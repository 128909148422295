import { GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';

export const dateFilterOperators: GridFilterOperator[] = [
    {
        label: 'today',
        value: 'today',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            filterItem.value = dayjs().format('MM-DD-YYYY');
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }

            return (params): boolean => dayjs(params.value as string).isSame(dayjs(), 'day');
        },
    },
    {
        label: 'yesterday',
        value: 'yesterday',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            filterItem.value = dayjs().format('MM-DD-YYYY');
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }

            const yesterday = dayjs().subtract(1, 'day').format('MM-DD-YYYY');

            return (params): boolean => dayjs(params.value as string).isSame(yesterday, 'day');
        },
    },
    {
        label: 'last 7 days',
        value: 'lastSevenDays',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            filterItem.value = dayjs().format('MM-DD-YYYY');
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }

            const sevenDaysAgo = dayjs().subtract(7, 'day').format('MM-DD-YYYY');

            return (params): boolean => dayjs(params.value as string).isAfter(sevenDaysAgo);
        },
    },
    {
        label: 'last 30 days',
        value: 'lastThirtyDays',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            filterItem.value = dayjs().format('MM-DD-YYYY');
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }

            const thirtyDaysAgo = dayjs().subtract(30, 'day').format('MM-DD-YYYY');

            return (params): boolean => dayjs(params.value as string).isAfter(thirtyDaysAgo);
        },
    },
];
