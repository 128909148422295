import { FilterAlt } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

type TableWithFiltersLayoutProps = {
    children: React.ReactNode;
    filters: React.ReactNode[];
};

const TableWithFiltersLayout = ({ children, filters }: TableWithFiltersLayoutProps) => (
    <Grid container columnSpacing='15px' height='100%' maxHeight='calc(100vh - 175px)'>
        <Grid item xs={10}>
            {children}
        </Grid>
        <Grid
            item
            xs={2}
            sx={{
                height: '100%',
                overflowY: 'auto',
                padding: '5px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    margin: '5px 0',
                }}
            >
                <FilterAlt sx={{ fontSize: '20px' }} />
                <Typography variant='body2' fontWeight='bold' sx={{ marginBottom: '10px' }}>
                    Quick Filters
                </Typography>
            </Box>
            {filters.map((filter, index) => (
                <Box
                    key={index}
                    sx={{
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '10px',
                    }}
                >
                    {filter}
                </Box>
            ))}
        </Grid>
    </Grid>
);

export default TableWithFiltersLayout;
