import React, { useState, useEffect } from 'react';
import { GridCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import DetailDrawer from '../templates/DetailDrawerLayout';
import type { DrawerHeaderAdvertiserInfo, DrawerHeaderPlacementInfo } from '../templates/DetailDrawerLayout';
import DynamicTabs from './DynamicTabs';
import CheckDetailsTab from './CheckDetailsTab';
import AboutMonitorTab from './AboutMonitorTab';
import ActivityHistoryTab from './ActivityHistoryTab';
import PacingHistoryTab from './PacingHistoryTab';

type MonitorDetailDrawerProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    cellParams?: GridCellParams;
    rowParams?: GridRowParams;
};

const MonitorDetailDrawer = ({ open, setOpen, cellParams, rowParams }: MonitorDetailDrawerProps) => {
    const hasCellParams = cellParams !== undefined;
    const row = hasCellParams ? cellParams?.row : rowParams?.row;
    const monitorName = hasCellParams ? cellParams?.colDef.field : row?.monitorFieldName;
    const monitorDisplayName = hasCellParams ? cellParams?.colDef.headerName : row?.monitorName;

    const [currentTab, setCurrentTab] = useState(0);
    useEffect(() => {
        if (open) {
            setCurrentTab(0);
        }
    }, [open]);

    const differenceProps = hasCellParams
        ? {
              checkDate: row[cellParams.colDef.field].checkDate,
              executionDate: row[cellParams.colDef.field].executionDate,
              message: row[cellParams.colDef.field].message,
              meta: row[cellParams.colDef.field].meta,
              status: row[cellParams.colDef.field].status,
          }
        : {
              checkDate: row.checkDate,
              executionDate: row.executionDate,
              message: row.message,
              meta: row.meta,
              status: row.status,
          };

    const commonProps = {
        advertiserName: row.advertiserName,
        buyType: row.buyType,
        placementId: row.placementId,
        placementName: row.placementName,
        platform: row.platform,
        monitorDisplayName,
        startDate: row.startDate,
        endDate: row.endDate,
    };

    const detailProps = { ...commonProps, ...differenceProps };

    const advertiser: DrawerHeaderAdvertiserInfo = { advertiserName: row.advertiserName };
    const placement: DrawerHeaderPlacementInfo = {
        platform: row.platform,
        placementName: row.placementName,
        campaignName: row.campaignName,
    };

    type TabContentMap = {
        [key: string]: {
            [index: number]: JSX.Element;
        };
    };

    const tabContentMap: TabContentMap = {
        launchVerification: {
            0: <CheckDetailsTab {...detailProps} />,
            1: <AboutMonitorTab monitorName={monitorName as MonitorRule} />,
        },
        deliveryCheck: {
            0: <CheckDetailsTab {...detailProps} />,
            1: <AboutMonitorTab monitorName={monitorName as MonitorRule} />,
        },
        marginThreshold: {
            0: <CheckDetailsTab {...detailProps} />,
            1: <AboutMonitorTab monitorName={monitorName as MonitorRule} />,
        },
        activityTrend: {
            0: <CheckDetailsTab {...detailProps} />,
            1: <ActivityHistoryTab placementId={row.placementId} />,
            2: <AboutMonitorTab monitorName={monitorName as MonitorRule} />,
        },
        flightPacing: {
            0: <CheckDetailsTab {...detailProps} />,
            1: <PacingHistoryTab {...detailProps} />,
            2: <AboutMonitorTab monitorName={monitorName as MonitorRule} />,
        },
    };

    const TabContent = tabContentMap[monitorName]?.[currentTab];

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <DetailDrawer
            open={open}
            setOpen={setOpen}
            advertiser={advertiser}
            placement={placement}
            onClose={() => setCurrentTab(0)}
        >
            <DynamicTabs onTabChange={handleTabChange} monitorName={monitorName} currentTabIndex={currentTab} />
            {TabContent || <div>No content available</div>}
        </DetailDrawer>
    );
};

export default MonitorDetailDrawer;
