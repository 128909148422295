export const checkAdvertiserNoPlacementData = (
    data: FormattedPlacement[],
    advertisers: FollowedAdvertiser[]
): FollowedAdvertiser[] => {
    const advertiserNoPlacementData: FollowedAdvertiser[] = [];

    advertisers.forEach((advertiser) => {
        const advertiserData = data.filter(
            (placement) => placement.advertiserName === advertiser.advertiser.advertiserName
        );
        if (advertiserData.length === 0) {
            advertiserNoPlacementData.push(advertiser);
        }
    });

    return advertiserNoPlacementData;
};

export const checkAdvertiserNoPlacementDataForEvents = (
    data: FormattedMonitorEvent[],
    advertisers: FollowedAdvertiser[]
): FollowedAdvertiser[] => {
    const advertiserNoPlacementData: FollowedAdvertiser[] = [];

    advertisers.forEach((advertiser) => {
        const advertiserData = data.filter(
            (placement) => placement.advertiserName === advertiser.advertiser.advertiserName
        );
        if (advertiserData.length === 0) {
            advertiserNoPlacementData.push(advertiser);
        }
    });

    return advertiserNoPlacementData;
};
