export const generateConfigFilterInputs = <T>(
    data: T[],
    getColumnValue: (item: T) => string,
    operator: string,
    labelMappings?: { [key: string]: string }
) => {
    const uniqueValues: string[] = [...new Set(data.map(getColumnValue))];

    if (labelMappings) {
        return uniqueValues.map((value) => ({
            label: labelMappings[value],
            value,
            operator,
        }));
    }

    return uniqueValues.map((value) => ({
        label: value,
        value,
        operator,
    }));
};
