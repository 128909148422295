import colors from './colors';

export const theme = {
    palette: {
        primary: {
            light: colors.lightBlue,
            main: colors.blue,
            dark: colors.darkBlue,
        },
        secondary: {
            light: colors.lightYellow,
            main: colors.yellow,
            dark: colors.darkYellow,
        },
        error: {
            main: 'rgb(200, 20, 32)',
        },
        warning: {
            main: 'rgb(251, 173, 25)',
        },
        info: {
            main: 'rgb(252, 209, 25)',
        },
        success: {
            main: 'rgb(0, 125, 198)',
        },
        globalMessages: {
            default: {
                light: 'rgb(241, 241, 241)',
                main: 'rgb(145, 145, 145)',
                dark: 'rgb(96, 96, 96)',
            },
            error: {
                light: 'rgb(249, 237, 236)',
                main: 'rgb(200, 20, 32)',
                dark: 'rgb(94, 4, 10)',
            },
            warning: {
                light: 'rgb(255, 247, 228)',
                main: 'rgb(251, 173, 25)',
                dark: 'rgb(255, 184, 18)',
            },
            info: {
                light: 'rgb(253, 251, 237)',
                main: 'rgb(252, 209, 25)',
                dark: 'rgb(251, 173, 25)',
            },
            success: {
                light: 'rgb(236, 244, 249)',
                main: 'rgb(0, 125, 198)',
                dark: 'rgb(11, 52, 70)',
            },
        },
        text: {
            primary: colors.midnightBlue,
            secondary: colors.darkGrey,
            light: 'rgb(92, 87, 71, .8)',
        },
        others: { ...colors },
    },
    typography: {
        fontFamily: ['Lato', 'Helvetica', 'sans-serif'].join(','),
    },
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
    },
};

export const HEADERS = {
    pageHeader: 'h3',
    mediumHeader: 'h5',
    smallHeader: 'h6',
};
