export const phaseGroupings: { phase: Phase; monitors: MonitorRule[] }[] = [
    {
        phase: 'Launch',
        monitors: ['launchVerification'],
    },
    {
        phase: 'Delivery',
        monitors: ['deliveryCheck', 'activityTrend', 'flightPacing', 'marginThreshold'],
    },
    {
        phase: 'Post',
        monitors: [],
    },
];
