import { Typography } from '@mui/material';
import { Box } from '@mui/system';

type ViewHeaderProps = {
    title: string;
    icon?: React.ReactNode;
};

const ViewHeader = ({ title, icon }: ViewHeaderProps) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px 0',
            marginRight: '20px',
        }}
    >
        {icon}
        <Typography sx={{ marginLeft: '10px' }} variant='h4'>
            {title}
        </Typography>
    </Box>
);

export default ViewHeader;
