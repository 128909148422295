import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Header, StatusCodes, navigateToLogin, AuthContext } from '@goodwaygroup/ui-ux';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import LoadPendo from '../pendo/LoadPendo';
import LeftNav from '../molecules/LeftNav';

const GRID_TEMPLATE_ROWS = '70px auto';
const GRID_TEMPLATE_COLUMNS = '40px auto';
function PageLayout({ user }) {
    const { loginUrl, preventLoginRedirect } = useContext(AuthContext);

    const doLogout = () => {
        // Clear local storage when logging out
        localStorage.clear();

        // Navigate to login page if not prevented
        if (!preventLoginRedirect) {
            navigateToLogin(loginUrl, StatusCodes.USER_LOGGED_OUT);
        }
    };

    return (
        <>
            <LoadPendo user={user} />
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: GRID_TEMPLATE_ROWS,
                    gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                    height: '100vh',
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ display: 'flex', gridRow: '1 / 2', gridColumn: '1 / 3' }}>
                    <Header user={user} logoHrefUrl='/' styles={{ zIndex: 1300 }} doLogout={doLogout} />
                </Box>
                <Box sx={{ gridRow: '2 / 3', gridColumn: '1 / 2', display: 'flex' }}>
                    <LeftNav user={user} />
                </Box>
                <Box sx={{ gridRow: '2 / 3', gridColumn: '2 / 3', display: 'flex' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: '1 1 auto',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                paddingLeft: '40px',
                                paddingRight: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                margin: 0,
                            }}
                        >
                            <Outlet />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

PageLayout.propTypes = {
    user: PropTypes.object.isRequired,
};

export default PageLayout;
