import React, { useState } from 'react';
import {
    DataGridPro,
    GridColDef,
    GridToolbar,
    GridRenderCellParams,
    GridFilterModel,
    GridCellParams,
    getGridDateOperators,
    GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { Box, Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { max } from 'lodash';
import dayjs from 'dayjs';
import PlatformIcon from '../atoms/PlatformIcon';
import HeaderCell from '../atoms/HeaderCell';
import { dateFilterOperators } from '../../operators/dateFilterOperators';
import Env from '../../helpers/Env';
import MonitorDetailDrawer from './MonitorDetailDrawer';
import MonitorStatusRenderer from '../atoms/MonitorStatusRenderer';
import { checkAdvertiserNoPlacementData } from '../../helpers/checkAdvertiserNoPlacementData';

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

type MyPlacementsDataGridProps = {
    data: FormattedPlacement[];
    advertisers: FollowedAdvertiser[];
    filterModel: GridFilterModel;
    setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
    columnVisibilityModel: GridColumnVisibilityModel;
    setColumnVisibilityModel: React.Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
};

const CELL_WIDTH = 90;

const monitorCellStyles = {
    width: CELL_WIDTH,
    maxWidth: CELL_WIDTH,
    minWidth: CELL_WIDTH,
    cellClassName: 'qa-datagrid-monitor--cell',
};

const monitorColumns = [
    'launchVerification',
    'deliveryCheck',
    'activityTrend',
    'ecpaTrend',
    'matchingBudget',
    'flightPacing',
    'marginThreshold',
    'overspend',
];

const MyPlacementsDataGrid = ({
    data,
    advertisers,
    filterModel,
    setFilterModel,
    columnVisibilityModel,
    setColumnVisibilityModel,
}: MyPlacementsDataGridProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [cellParams, setCellParams] = useState<GridCellParams | null>(null);
    const navigate = useNavigate();
    const defaultDateOperators = getGridDateOperators().filter(
        (op) => op.value !== 'isEmpty' && op.value !== 'isNotEmpty' && op.value !== 'not'
    );

    const columns: GridColDef[] = [
        {
            headerName: 'Date',
            field: 'executionDate',
            filterOperators: [...dateFilterOperators, ...defaultDateOperators],
            valueGetter: (params) => {
                const hasMonitorData = monitorColumns.some((col) => params.row[col]);
                const availableMonitorDataKeys = hasMonitorData && monitorColumns.filter((col) => params.row[col]);

                const executionDates: string[] | boolean =
                    availableMonitorDataKeys &&
                    availableMonitorDataKeys.map((monitor) => {
                        const executionDate = params.row[monitor]?.executionDate;

                        return executionDate;
                    });

                const latestexecutionDate =
                    executionDates && max(executionDates.map((date) => dayjs(date).format('MM/DD/YYYY')));

                return latestexecutionDate || null;
            },
        },
        {
            headerName: 'Platform',
            headerAlign: 'center',
            field: 'platform',
            renderCell: (props: GridRenderCellParams) => (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <PlatformIcon platform={props?.value} size={30} />
                </Box>
            ),
        },
        {
            headerName: 'Campaign Name',
            field: 'campaignName',
            minWidth: 300,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Placement Name',
            field: 'placementName',
            minWidth: 300,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Advertiser',
            field: 'advertiserName',
            minWidth: 200,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Buy Type',
            field: 'buyType',
            maxWidth: 100,
            renderCell: (params) => {
                const formatValue = `${params.value.charAt(0).toUpperCase()}${params.value.slice(1)}`;

                return (
                    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 100, height: 'auto' }}>
                        {formatValue}
                    </div>
                );
            },
        },
        {
            headerName: 'Phase',
            field: 'currentPhase',
            maxWidth: 100,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 100, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            ...monitorCellStyles,
            headerName: 'Launch Verification',
            field: 'launchVerification',
            renderCell: (params) => <MonitorStatusRenderer params={params} setIsOpen={setIsOpen} />,
            renderHeader: () => <HeaderCell headerName='Launch Verification' />,
            flex: 1,
        },
        {
            ...monitorCellStyles,
            headerName: 'Delivery Check',
            field: 'deliveryCheck',
            renderCell: (params) => <MonitorStatusRenderer params={params} setIsOpen={setIsOpen} />,
            renderHeader: () => <HeaderCell headerName='Delivery Check' />,
            flex: 1,
        },
        {
            ...monitorCellStyles,
            headerName: 'Margin Threshold',
            field: 'marginThreshold',
            renderCell: (params) => <MonitorStatusRenderer params={params} setIsOpen={setIsOpen} />,
            renderHeader: () => <HeaderCell headerName='Margin Threshold' />,
            flex: 1,
        },
        {
            ...monitorCellStyles,
            headerName: 'Activity Trend',
            field: 'activityTrend',
            renderCell: (params) => (
                <MonitorStatusRenderer
                    params={params}
                    setIsOpen={setIsOpen}
                    message='No activities found for this monitor. Confirm you have activities configured for this placement.'
                />
            ),
            renderHeader: () => <HeaderCell headerName='Activity Trend' />,
            flex: 1,
        },
        // {
        //     ...monitorCellStyles,
        //     headerName: 'ECPA Trend',
        //     field: 'ecpaTrend',
        //     renderCell: (params) =>
        //         params.formattedValue && (
        //             <MonitorCheck
        //                 setDrawerOpen={setIsOpen}
        //                 status={checkMonitorStatus(params)}
        //                 name={params.formattedValue.name}
        //                 message={params.formattedValue.message}
        //                 executionDate={params.formattedValue.executionDate}
        //             />
        //         ),
        //     renderHeader: () => <HeaderCell headerName='ECPA Trend' />,
        //     flex: 1,
        // },
        // {
        //     ...monitorCellStyles,
        //     headerName: 'Matching Budget',
        //     field: 'matchingBudget',
        //     renderCell: (params) =>
        //         params.formattedValue && (
        //             <MonitorCheck
        //                 setDrawerOpen={setIsOpen}
        //                 status={checkMonitorStatus(params)}
        //                 name={params.formattedValue.name}
        //                 message={params.formattedValue.message}
        //                 executionDate={params.formattedValue.executionDate}
        //             />
        //         ),
        //     renderHeader: () => <HeaderCell headerName='Matching Budget' />,
        //     flex: 1,
        // },
        {
            ...monitorCellStyles,
            headerName: 'Flight Pacing',
            field: 'flightPacing',
            renderCell: (params) => <MonitorStatusRenderer params={params} setIsOpen={setIsOpen} />,
            renderHeader: () => <HeaderCell headerName='Flight Pacing' />,
            flex: 1,
        },
        // {
        //     ...monitorCellStyles,
        //     headerName: 'No Overspend',
        //     field: 'overspend',
        //     renderCell: (params) =>
        //         params.formattedValue && (
        //             <MonitorCheck
        //                 setDrawerOpen={setIsOpen}
        //                 status={checkMonitorStatus(params)}
        //                 name={params.formattedValue.name}
        //                 message={params.formattedValue.message}
        //                 executionDate={params.formattedValue.executionDate}
        //             />
        //         ),
        //     renderHeader: () => <HeaderCell headerName='No Overspend' />,
        //     flex: 1,
        // },
    ];

    const handleCellClick = (params: GridCellParams) => {
        setCellParams(params);
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: 'calc(100% + 40px)',
                maxHeight: '100%',
                justifyContent: 'flex-start',
                '& .qa-datagrid-monitor--cell': {
                    padding: ' 0 !important',
                    margin: 0,
                },
                width: '100%',
            }}
        >
            <DataGridPro
                sx={{
                    '& .MuiDataGrid-virtualScroller': {
                        pointerEvents: !data.length ? 'none' : 'all',
                    },
                }}
                density='standard'
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'campaignName', sort: 'desc' }],
                    },
                }}
                rows={data}
                columns={columns}
                getRowId={(row) => row.placementId}
                rowHeight={90}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        printOptions: { disableToolbarButton: true },
                    },
                }}
                filterModel={filterModel}
                onFilterModelChange={(prev) => setFilterModel(prev)}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                experimentalFeatures={{ columnGrouping: true }}
                components={{
                    Toolbar: GridToolbar,
                    NoRowsOverlay: () => {
                        const noPlacementAdvertisers = checkAdvertiserNoPlacementData(data, advertisers);

                        if (noPlacementAdvertisers.length === advertisers.length && advertisers.length > 0) {
                            return (
                                <Stack height='100%' alignItems='center' justifyContent='center'>
                                    <Typography sx={{ marginBottom: '10px' }} variant='h5'>
                                        No Active Placements
                                    </Typography>
                                    <Typography variant='subtitle1' sx={{ marginBottom: '10px' }}>
                                        The following advertisers have no active placements
                                    </Typography>
                                    <List sx={{ marginBottom: '10px' }}>
                                        {noPlacementAdvertisers.map((advertiser) => (
                                            <ListItem key={advertiser.id} dense sx={{ justifyContent: 'center' }}>
                                                <ListItemText
                                                    primary={advertiser.advertiser.advertiserName}
                                                    primaryTypographyProps={{ fontWeight: 'bold', textAlign: 'center' }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Button
                                        variant='contained'
                                        sx={{ minWidth: '100px' }}
                                        onClick={() => navigate('/advertisers')}
                                    >
                                        Go To Advertisers
                                    </Button>
                                </Stack>
                            );
                        }
                        return (
                            <Stack height='100%' alignItems='center' justifyContent='center'>
                                <Typography sx={{ marginBottom: '10px' }} variant='h5'>
                                    Follow Advertisers To View Campaigns/Placements
                                </Typography>
                                <Typography variant='body2' sx={{ marginBottom: '20px' }}>
                                    Placements from advertisers you follow will show here
                                </Typography>
                                <Button
                                    variant='contained'
                                    sx={{ minWidth: '100px' }}
                                    onClick={() => navigate('/advertisers')}
                                >
                                    Go To Advertisers
                                </Button>
                            </Stack>
                        );
                    },
                }}
                onCellClick={(params) => {
                    const clickedColumn = params.field;

                    return monitorColumns.includes(clickedColumn) && params.row?.[clickedColumn]?.status
                        ? handleCellClick(params)
                        : setCellParams(null);
                }}
            />
            {cellParams && <MonitorDetailDrawer open={isOpen} setOpen={setIsOpen} cellParams={cellParams} />}
        </Box>
    );
};

export default MyPlacementsDataGrid;
