import { useQuery, gql } from '@apollo/client';
import { Box } from '@mui/system';
import ActivityHistoryChart from '../atoms/ActivityHistoryChart';
import LoadingBlock from '../atoms/LoadingBlock';

const GET_ACTIVITY_HISTORY = gql`
    query getHistoricalActivityData($placementId: ID!) {
        getHistoricalActivityData(placementId: $placementId) {
            id
            date
            activities
            message
        }
    }
`;

type ActivityHistoryTabProps = {
    placementId: string;
};

const ActivityHistoryTab = ({ placementId }: ActivityHistoryTabProps) => {
    const { loading, error, data } = useQuery(GET_ACTIVITY_HISTORY, {
        variables: {
            placementId,
        },
    });

    if (loading) return <LoadingBlock />;

    if (error) return <div>Error</div>;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                maxHeight: '500px',
            }}
        >
            <h3 style={{ textAlign: 'center', fontFamily: 'Roboto' }}>Daily Activities</h3>
            <ActivityHistoryChart data={data?.getHistoricalActivityData} />
        </Box>
    );
};

export default ActivityHistoryTab;
