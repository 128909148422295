import React, { useState, useCallback, useContext } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Menu as MenuIcon, Business, Beenhere, Speed, Toc, MonitorHeart, OpenInNew } from '@mui/icons-material';
import { AppContext } from '../context/AppContext';

const LeftNav = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { location } = useContext(AppContext);
    const pathname = location?.pathname;

    const toggleDrawer = useCallback((open: boolean) => {
        setIsDrawerOpen(open);
    }, []);

    const handleMouseEnter = useCallback(() => {
        toggleDrawer(true);
    }, [toggleDrawer]);

    const handleListItemClick = useCallback(() => {
        setIsDrawerOpen(false);
    }, []);

    return (
        <>
            <Box
                onClick={handleMouseEnter}
                sx={{
                    width: '100%',
                    borderRight: '1px solid #E0E0E0',
                    display: 'flex',
                    padding: '12px',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    cursor: 'pointer',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <MenuIcon sx={{ paddingTop: '6px' }} />
                    <Typography>Menu</Typography>
                </Box>
            </Box>
            <Drawer anchor='left' open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
                <List sx={{ paddingTop: '80px', width: '250px' }}>
                    <Typography variant='body1' fontWeight={'bold'} sx={{ paddingLeft: '15px', paddingBottom: '5px' }}>
                        Smartguard Views
                    </Typography>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to='/'
                            selected={pathname === '/'}
                            onClick={handleListItemClick}
                        >
                            <ListItemIcon>
                                <Beenhere />
                            </ListItemIcon>
                            <ListItemText primary='Campaign Status' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to='/pacing'
                            selected={pathname === '/pacing'}
                            onClick={handleListItemClick}
                        >
                            <ListItemIcon>
                                <Speed />
                            </ListItemIcon>
                            <ListItemText primary='Pacing Insights' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to='/events'
                            selected={pathname === '/events'}
                            onClick={handleListItemClick}
                        >
                            <ListItemIcon>
                                <Toc />
                            </ListItemIcon>
                            <ListItemText primary='Event Log' />
                        </ListItemButton>
                    </ListItem>
                    <Typography
                        variant='body1'
                        fontWeight={'bold'}
                        sx={{ paddingLeft: '15px', paddingTop: '20px', paddingBottom: '5px' }}
                    >
                        Configuration
                    </Typography>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to='/advertisers'
                            selected={pathname === '/advertisers'}
                            onClick={handleListItemClick}
                        >
                            <ListItemIcon>
                                <Business />
                            </ListItemIcon>
                            <ListItemText primary='Follow Advertisers' />
                        </ListItemButton>
                    </ListItem>
                    <Typography
                        variant='body1'
                        fontWeight={'bold'}
                        sx={{ paddingLeft: '15px', paddingTop: '20px', paddingBottom: '5px' }}
                    >
                        External QA Links
                    </Typography>
                    <ListItem disablePadding>
                        <ListItemButton
                            component='a'
                            href='https://portal.goodwaygroup.com/pixel-qa'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <ListItemIcon>
                                <MonitorHeart />
                            </ListItemIcon>
                            <ListItemText primary='Pixel QA Tool' />
                            <OpenInNew sx={{ fontSize: '14px' }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
};

export default LeftNav;
