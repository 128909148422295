import compact from 'lodash/compact';
import { useQuery, gql } from '@apollo/client';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import AdvertiserView from '../molecules/AdvertiserView';
import LoadingBlock from '../atoms/LoadingBlock';
import FollowAdvertiserButton from '../atoms/FollowAdvertiserButton';

const GET_ALL_MEDIAOCEAN_ADVERTISERS = gql`
    query getAllAdvertisers {
        getAllMediaoceanAdvertisers {
            id
            advertiserCode
            advertiserName
            advertiserShortName
            locationCompanyId
            locationName
            isFollowed
        }
    }
`;

const columns: GridColDef[] = [
    {
        headerName: 'Name',
        field: 'advertiserName',
        flex: 1,
    },
    {
        headerName: 'Short Name',
        field: 'advertiserShortName',
        flex: 1,
    },
    {
        headerName: 'Code',
        field: 'advertiserCode',
        flex: 1,
    },
    {
        headerName: 'Location Company ID',
        field: 'locationCompanyId',
        flex: 1,
    },
    {
        headerName: 'Location Name',
        field: 'locationName',
        flex: 1,
    },
    {
        headerName: 'Followed',
        field: 'isFollowed',
        flex: 1,
        renderCell: (props: GridRenderCellParams) => (
            <FollowAdvertiserButton advertiserCode={props?.row?.advertiserCode} isFollowed={props?.value} />
        ),
    },
];

const AdvertisersPage = () => {
    const { loading, data } = useQuery(GET_ALL_MEDIAOCEAN_ADVERTISERS, { errorPolicy: 'all' });
    const rows: Advertiser[] = compact(data?.getAllMediaoceanAdvertisers);
    if (loading) return <LoadingBlock />;
    return <AdvertiserView rows={rows} columns={columns} />;
};

export default AdvertisersPage;
