import * as React from 'react';
import { Chip, useTheme } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

type PacingPercentageProps = {
    percentage: number;
    status: PrismaMonitorStatus;
};

const PacingPercentage: React.FC<PacingPercentageProps> = ({ percentage, status }) => {
    const theme = useTheme();

    const getColor = (): string => {
        switch (status) {
            case 'SUCCESS':
                return theme.palette.success.main;
            case 'WARNING':
                return theme.palette.warning.main;
            case 'FAILURE':
                return theme.palette.error.main;
            default:
                return theme.palette.grey[500];
        }
    };

    const color = getColor();
    const shouldShowStar = status === 'SUCCESS';

    return (
        <Chip
            avatar={
                shouldShowStar ? (
                    <StarIcon style={{ width: 20, height: 20, color: 'gold', marginRight: '-10px' }} />
                ) : undefined
            }
            label={`${percentage}%`}
            style={{
                paddingTop: '1px',
                backgroundColor: color,
                fontSize: '18px',
                color: color === theme.palette.warning.main ? 'black' : 'white', // Ensuring contrast for yellow
                minWidth: '90px',
            }}
        />
    );
};

export default PacingPercentage;
