export const monitorDescriptions: {
    [rule in MonitorRule]: { description: string; example: string };
} = {
    launchVerification: {
        description:
            "<p><strong>The Launch Verification Monitor</strong> checks daily for impressions on each new advertising placement's scheduled launch day. If it detects impressions, indicating a successful launch, the monitor will confirm this success and will not run again for that specific placement.</p>" +
            "<p>However, if the monitor does not find any impressions and marks the launch as an <em>'FAILURE'</em>, it will continue to run daily checks. This process repeats until it either detects the necessary impressions, confirming a successful launch, or until the monitoring period for that placement ends.</p>",
        example:
            "<p><strong>Campaign Name:</strong> Dave's Ice Cream - Summer Blast Off</p>" +
            '<p><strong>Scheduled Launch Date:</strong> July 1, 2023</p>' +
            '<p><strong>Successful Launch</strong><br>' +
            "On July 1, the monitor detects impressions for the campaign, marking it as a <em>'SUCCESS'</em>. It won't run again for this campaign.</p>" +
            '<p><strong>Launch Failure and Follow-Up</strong><br>' +
            "If no impressions are found on July 1, the launch is marked as a <em>'FAILURE'</em>.<br>" +
            'The monitor then checks daily until it detects impressions or until the monitoring period ends.</p>',
    },
    activityTrend: {
        description:
            "<p><strong>The Activity Trend Monitor</strong> incorporates a high-level standard deviation check to evaluate your campaign's performance. Standard deviation is a statistical measure that shows how much variation or dispersion exists from the average (mean). In simple terms, it helps to understand if the campaign activities are consistent or if there are significant fluctuations.</p>" +
            "<p>The monitor sets thresholds for these deviations. If the campaign's activity levels stay within these predefined limits, it's considered normal and marked as <em>'SUCCESS'</em>. However, if activities go beyond these limits, indicating either much higher or lower activity than usual, the monitor flags this as a <em>'FAILURE'</em>. This check is crucial for quickly spotting any unusual trends in your campaign's performance, ensuring you can take timely action if needed.</p>",
        example:
            "<p><strong>Campaign Name:</strong> Dave's Ice Cream - Spring Fling</p>" +
            '<p><strong>Check Date:</strong> April 15, 2023</p>' +
            '<p><strong>Scenario 1: Sufficient Data and Normal Activity</strong><br>' +
            'By April 15, the campaign has been running for 5 days. ' +
            'The monitor calculates the standard deviation of activities and finds them within the normal range. ' +
            "It marks the campaign as <em>'SUCCESS'</em>, indicating consistent and expected activity levels.</p>" +
            '<p><strong>Scenario 2: Insufficient Data</strong><br>' +
            'The campaign started on April 14, 2023. ' +
            'On April 15, the monitor notes that only 2 days of data are available. ' +
            "It reports an <em>'INCOMPLETE'</em> status, indicating more time is needed to gather sufficient data for a reliable analysis.</p>" +
            '<p><strong>Scenario 3: Unusual Activity Trend</strong><br>' +
            'The campaign has been running for over 5 days. ' +
            'The monitor detects significant deviation in activities from the calculated average. ' +
            "It flags a <em>'FAILURE'</em>, suggesting unusual activity trends that may require review.</p>",
    },
    deliveryCheck: {
        description:
            "<p><strong>The Delivery Check Monitor</strong> is designed to ensure that your advertising placements are actively delivering as expected. It operates by checking for impressions on a specified date. If impressions are found, it confirms successful delivery with a <em>'SUCCESS'</em> status; otherwise, it flags a <em>'FAILURE'</em> status, indicating no impressions were detected. This process helps in promptly identifying and addressing any delivery issues with your campaign placements.</p>",
        example:
            "<p><strong>Campaign Name:</strong> Dave's Ice Cream - Winter Wonderland</p>" +
            '<p><strong>Check Date:</strong> December 10, 2023</p>' +
            '<p><strong>Successful Delivery</strong><br>' +
            'On December 10, the monitor checks and finds impressions for the campaign. ' +
            "It confirms successful delivery with a <em>'SUCCESS'</em> status.</p>" +
            '<p><strong>Failed Delivery</strong><br>' +
            'On December 10, the monitor finds no impressions. ' +
            "It flags a <em>'FAILURE'</em>, suggesting the campaign isn't delivering as expected.</p>",
    },
    flightPacing: {
        description:
            "<p><strong>The Flight Pacing Monitor</strong> is designed to track the budget pacing of your advertising campaigns. It ensures that your campaign's spending is aligned with the planned budget over the campaign's duration. The monitor calculates the pacing percentage by comparing the actual amount spent so far with the projected spend at that point in the campaign's timeline.</p>",
        example:
            "<p><strong>Campaign Name:</strong> Dave's Ice Cream - Summer Scoops</p>" +
            '<p><strong>Check Date:</strong> July 10, 2023</p>' +
            '<p><strong>Inputs:</strong></p>' +
            '<ul>' +
            '<li><strong>Start Date:</strong> The beginning of the campaign, e.g., July 1, 2023.</li>' +
            '<li><strong>End Date:</strong> The end date of the campaign, e.g., July 31, 2023.</li>' +
            '<li><strong>Billable Rate:</strong> The rate at which the campaign is billed, e.g., $50 per thousand impressions.</li>' +
            '<li><strong>Delivered Amount:</strong> Actual figures achieved by the campaign, like impressions or spend. For example, $20,000 spent so far.</li>' +
            '<li><strong>Gross Billable:</strong> The total budget for the campaign, e.g., $100,000.</li>' +
            '<li><strong>Pacing Type:</strong> The metric being monitored, currently focused on budget pacing.</li>' +
            '</ul>' +
            '<p><strong>Scenario 1: On-Target Pacing</strong></p>' +
            '<p>Total campaign duration: 31 days (July 1 - July 31).</p>' +
            '<p>Duration until check date: 10 days (July 1 - July 10).</p>' +
            '<p>Expected spend by July 10: (10/31) * $100,000 = $32,258.</p>' +
            '<p>Actual spend: $20,000.</p>' +
            '<p>Pacing Percentage: ($20,000 / $32,258) * 100 = 62%.</p>' +
            "<p>If the pacing percentage falls within the ideal range (e.g., 96% - 105%), the monitor marks it as <em>'SUCCESS'</em>, indicating healthy pacing.</p>" +
            '<p><strong>Scenario 2: Off-Pace</strong></p>' +
            "<p>If the pacing percentage is below the lower threshold (e.g., 84%) or above the upper threshold (e.g., 120%), it's marked as <em>'FAILURE'</em>, suggesting the campaign is spending too slowly or too quickly.</p>" +
            '<p><strong>Scenario 3: Close Monitoring</strong></p>' +
            "<p>If the pacing percentage is near the warning thresholds (e.g., 85% - 119%), it's labeled <em>'WARNING'</em>, signaling the need for closer monitoring or adjustments.</p>",
    },
    marginThreshold: {
        description:
            "<p><strong>The Margin Threshold Monitor</strong> is designed to help maintain the financial health of your advertising campaigns. It works by calculating the margin ratio, which is the difference between the amount billed to clients and the amount paid to suppliers, relative to the amount billed. This ratio is then compared against predefined thresholds to ensure that the campaign's profitability stays within an acceptable range.</p>",
        example:
            "<p><strong>Campaign Name:</strong> Dave's Ice Cream - Autumn Delights</p>" +
            '<p><strong>Check Date:</strong> October 20, 2023</p>' +
            '<p><strong>Inputs:</strong></p>' +
            '<ul>' +
            '<li><strong>Amount to Bill (Revenue):</strong> The total amount billed to the client for this campaign, e.g., $100,000.</li>' +
            '<li><strong>Amount to Pay (Cost):</strong> The total cost incurred for the campaign, e.g., $70,000.</li>' +
            '<li><strong>Prisma Billable:</strong> The estimated billable amount as per Prisma, e.g., $95,000.</li>' +
            '<li><strong>Prisma Payable:</strong> The estimated payable amount as per Prisma, e.g., $65,000.</li>' +
            '<li><strong>Lower Threshold:</strong> The lower limit of acceptable margin ratio, e.g., 0.97.</li>' +
            '<li><strong>Upper Threshold:</strong> The upper limit of acceptable margin ratio, e.g., 1.2.</li>' +
            '</ul>' +
            '<p><strong>Scenario 1: Margin Within Thresholds</strong></p>' +
            '<p>The delivered margin percentage is calculated as (Amount to Bill - Amount to Pay) / Amount to Bill, e.g., (100,000 - 70,000) / 100,000 = 0.3 or 30%.</p>' +
            '<p>The Prisma margin percentage is (1 - Prisma Payable / Prisma Billable), e.g., (1 - 65,000 / 95,000) = 0.3158 or 31.58%.</p>' +
            '<p>The percentage of margin delivered relative to the Prisma margin is 30% / 31.58% = 0.95.</p>' +
            "<p>Since 0.95 falls between the lower (0.97) and upper (1.2) thresholds, the status is <em>'SUCCESS'</em>.</p>" +
            '<p><strong>Scenario 2: Margin Outside Thresholds</strong></p>' +
            "<p>If the calculated margin percentage relative to Prisma margin falls outside the 0.97 to 1.2 range, the monitor marks the campaign as a <em>'FAILURE'</em>, indicating that the margin is not within the acceptable range.</p>",
    },
};
