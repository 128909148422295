import React from 'react';
import { Box, useTheme, Popover, Typography, Alert, AlertColor } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import BlockIcon from '@mui/icons-material/Block';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import dayjs from 'dayjs';

type MonitorCheckProps = {
    status: PrismaMonitorStatus | 'NOT_SUPPORTED' | 'NO_DATA';
    name?: string;
    executionDate?: string;
    message?: string;
    borderRight?: boolean;
    setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

type StatusMap = {
    [key in PrismaMonitorStatus | 'NOT_SUPPORTED' | 'NO_DATA']: {
        backgroundColor: string | null;
        icon: React.ReactElement | null;
        label?: string;
    };
};

function getSeverity(type: PrismaMonitorStatus | string): AlertColor {
    switch (type) {
        case 'FAILURE':
            return 'error';
        case 'SUCCESS':
            return 'success';
        case 'WARNING':
            return 'warning';
        case 'INSUFFICIENT_DATA':
            return 'warning';
        default:
            return 'info';
    }
}

const MonitorCheck = ({
    status,
    name = '',
    executionDate,
    message = '',
    borderRight = false,
    setDrawerOpen = () => null,
}: MonitorCheckProps) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const statusMap: StatusMap = {
        SUCCESS: {
            backgroundColor: '#00B860',
            icon: <CheckIcon />,
        },
        FAILURE: {
            backgroundColor: theme.palette.error.main,
            icon: <CloseIcon />,
        },
        WARNING: {
            backgroundColor: '#FCD119',
            icon: <PriorityHighIcon />,
        },
        DONE: {
            backgroundColor: '#d1d5db',
            icon: <CheckIcon />,
        },
        FUTURE: {
            backgroundColor: null,
            icon: null,
        },
        NOT_SUPPORTED: {
            backgroundColor: '#d1d5db',
            icon: <BlockIcon />,
            label: 'NOT SUPPORTED',
        },
        NO_DATA: {
            backgroundColor: '#d1d5db',
            icon: <QuestionMarkIcon />,
            label: 'NO DATA',
        },
        INSUFFICIENT_DATA: {
            backgroundColor: '#FCD119',
            icon: <PriorityHighIcon />,
        },
    };

    const selectedStatus = status ? statusMap[status] : { backgroundColor: null, icon: null };

    return (
        <Box
            sx={{
                borderRight: borderRight ? undefined : '1px solid #E0E0E0',
                boxSizing: 'border-box',
                height: '90px',
                width: '90px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            onClick={() => setDrawerOpen(true)}
        >
            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: selectedStatus?.backgroundColor,
                    color: '#fff',
                    cursor: status && 'pointer',
                }}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {selectedStatus?.icon}
            </Box>
            <Popover
                id='mouse-over-popover'
                sx={{
                    pointerEvents: 'none',
                    marginTop: '5px',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Alert icon={false} sx={{ padding: '10px' }} severity={getSeverity(status)}>
                    <Typography fontWeight='bold' variant='body1'>
                        {name} - {selectedStatus.label ? selectedStatus.label : status}
                    </Typography>
                    {executionDate && (
                        <Typography variant='body2'>{dayjs(executionDate).format('MM/DD/YYYY')}</Typography>
                    )}
                    <Typography variant='body2'>{message}</Typography>
                </Alert>
            </Popover>
        </Box>
    );
};

export default MonitorCheck;
