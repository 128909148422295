import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { checkMonitorAvailability } from '../../helpers/checkMonitorAvailability';
import { checkMonitorStatus } from '../../helpers/checkMonitorStatus';
import { checkMonitorInCurrentPhase } from '../../helpers/checkMonitorInCurrentPhase';
import MonitorCheck from './MonitorCheck';

type MonitorStatusRendererProps = {
    params: GridRenderCellParams;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    message?: string;
};

const MonitorStatusRenderer = ({ params, setIsOpen, message }: MonitorStatusRendererProps) => {
    const monitorAvailable = checkMonitorAvailability(params.field as MonitorRule, params.row.platform);
    const monitorInCurrentPhase = checkMonitorInCurrentPhase(params);

    if (!monitorAvailable && monitorInCurrentPhase) {
        return (
            <MonitorCheck
                status='NOT_SUPPORTED'
                name={`${params.row.platform}`}
                message={`This monitor is not supported for ${params.row.platform}`}
            />
        );
    }

    if (monitorAvailable && monitorInCurrentPhase && !params.formattedValue) {
        return (
            <MonitorCheck
                status='NO_DATA'
                name={`${params.row.platform}`}
                message={message || `No data found for ${params.row.platform}`}
            />
        );
    }

    return (
        params.formattedValue && (
            <MonitorCheck
                setDrawerOpen={setIsOpen}
                status={checkMonitorStatus(params)}
                name={params.formattedValue.name}
                message={params.formattedValue.message}
                executionDate={params.formattedValue.executionDate}
            />
        )
    );
};

export default MonitorStatusRenderer;
