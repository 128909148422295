import React from 'react';
import { Box } from '@mui/system';

type HeaderCellProps = {
    headerName: string;
    width?: string;
};

const HeaderCell = ({ headerName, width }: HeaderCellProps) => {
    const words = headerName.split(' ');

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                fontWeight: 'bold',
                height: '100%',
                justifyContent: 'center',
                lineHeight: '15px',
                textAlign: 'center',
                width: width || '90px',
                wordWrap: 'break-word',
            }}
        >
            {words.map((word, index) => {
                if (words.length > 2) {
                    return (
                        <React.Fragment key={index}>
                            {word}
                            {(index + 1) % 2 === 0 ? <br /> : ' '}
                        </React.Fragment>
                    );
                }
                return (
                    <React.Fragment key={index}>
                        {word}
                        {index < words.length - 1 && <br />}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};

export default HeaderCell;
