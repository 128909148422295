import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import { formatToUSCurrency } from '../../helpers/formatters';
import { chartToolTipStyle } from '../../style/chartTooltip';

const DEFAULT_STROKE_WIDTH = 2;
const X_AXIS_TIC_INTERVAL = 1;
const Y_AXIS_MAX_PADDING_PROPORTION = 1.15; // Set y-axis max to 115% of the highest value
const CURVE_TYPE = 'linear';

interface PacingChartProps {
    pacingHistory: PacingHistory[];
    startDate: string;
    endDate: string;
}

const getYAxisMax = (dataMax: number) => Math.round((dataMax * Y_AXIS_MAX_PADDING_PROPORTION) / 10) * 10;
interface AnnotatedPacingHistory {
    plannedAmountPerDay: number;
    failedAmount: number | null;
}
interface PacingHistoryAccumulator {
    sortedData: AnnotatedPacingHistory[];
    daysPassed: number;
    daysFailed: number;
    daysTotal: number;
}

const prepareData = (startDate: string, endDate: string, pacingHistory: PacingHistory[]) => {
    const totalDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
    const sortedData = pacingHistory
        .sort((a, b) => dayjs(a.checkDate).diff(dayjs(b.checkDate), 'day'))
        .reduce(
            (acc, cur) => {
                acc.sortedData.push({
                    ...cur,
                    plannedAmountPerDay: Math.floor((cur.plannedAmount / totalDays) * 100) / 100,
                    failedAmount: cur.status === 'FAILURE' ? cur.latestDateDeliveredAmount : null,
                });
                acc.daysTotal += 1;
                acc.daysFailed += cur.status === 'FAILURE' ? 1 : 0;
                acc.daysPassed += cur.status === 'SUCCESS' ? 1 : 0;
                return acc;
            },
            {
                sortedData: [],
                daysPassed: 0,
                daysFailed: 0,
                daysTotal: 0,
            } as PacingHistoryAccumulator
        );

    return sortedData;
};

const PacingChart: React.FC<PacingChartProps> = ({ startDate, endDate, pacingHistory }) => {
    const pacingHistoryObj = useMemo(
        () => prepareData(startDate, endDate, pacingHistory),
        [pacingHistory, startDate, endDate]
    );
    const { sortedData, daysPassed, daysFailed } = pacingHistoryObj;

    return (
        <div style={{ width: '100%', height: '30em' }}>
            <h3 style={{ textAlign: 'center' }}>Pacing</h3>
            <ResponsiveContainer width='100%' height='100%'>
                <LineChart
                    width={800}
                    height={500}
                    data={sortedData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 30,
                        bottom: 20,
                    }}
                    style={{ fontFamily: 'roboto' }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                        dataKey='checkDate'
                        angle={-45}
                        textAnchor='end'
                        minTickGap={100}
                        interval={X_AXIS_TIC_INTERVAL}
                        height={50}
                    />
                    <YAxis tickFormatter={formatToUSCurrency} domain={[0, getYAxisMax]} />
                    <Tooltip formatter={formatToUSCurrency} contentStyle={chartToolTipStyle} />
                    <Legend verticalAlign='top' align='right' />
                    <Line
                        type={CURVE_TYPE}
                        dataKey='plannedAmountPerDay'
                        name='Even Spend'
                        stroke='#1B1D31'
                        strokeWidth={DEFAULT_STROKE_WIDTH - 1}
                        activeDot={{ r: 6 }}
                        isAnimationActive={false}
                        strokeDasharray='3 3'
                        dot={false}
                    />
                    {daysPassed > 0 && (
                        <Line
                            type={CURVE_TYPE}
                            dataKey='latestDateDeliveredAmount'
                            name='Actual Spend'
                            stroke='#007DC6'
                            strokeWidth={DEFAULT_STROKE_WIDTH}
                            activeDot={{ r: 6 }}
                            isAnimationActive={false}
                            connectNulls={false}
                        />
                    )}
                    {daysFailed > 0 && (
                        <Line
                            type={CURVE_TYPE}
                            dataKey='failedAmount'
                            name='Actual Spend (Outside Threshold)'
                            stroke='#c60000'
                            strokeWidth={DEFAULT_STROKE_WIDTH}
                            activeDot={{ r: 6 }}
                            isAnimationActive={false}
                            connectNulls={false}
                        />
                    )}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PacingChart;
