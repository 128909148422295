import React from 'react';
import { Typography, Grid, Stack, Alert } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import PacingPercentage from '../atoms/PacingPercentage';
import { formatToUSCurrency, formatNumberWithCommas } from '../../helpers/formatters';
import { colorMap } from '../../constants/colorMap';

type PacingDetailTabProps = {
    index: number;
    rowParams: GridRowParams;
};

const PacingDetailTab: React.FC<PacingDetailTabProps> = ({ rowParams }) => {
    const { row } = rowParams;
    const {
        pacingPercentage,
        status,
        pacingType,
        plannedAmount,
        deliveredAmount,
        remainingDailyAmount,
        deliveredImpressions,
        remainingDailyImpressions,
    } = row;

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Alert severity={colorMap[row?.status as keyof typeof Alert]}>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1' component='span'>
                        {row?.message}
                    </Typography>
                </Alert>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Pacing:
                    </Typography>
                    <PacingPercentage percentage={pacingPercentage} status={status} />
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Execution Date:
                    </Typography>
                    <Typography>{dayjs(row.executionDate).format('MM/DD/YYYY')}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Check Date:
                    </Typography>
                    <Typography>{dayjs(row.checkDate).format('MM/DD/YYYY')}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Pacing Type:
                    </Typography>
                    <Typography>{pacingType}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Planned Amount:
                    </Typography>
                    <Typography>{formatToUSCurrency(plannedAmount)}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Delivered Amount:
                    </Typography>
                    <Typography>{formatToUSCurrency(deliveredAmount)}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Remaining Daily Amount:
                    </Typography>
                    <Typography>{formatToUSCurrency(remainingDailyAmount)}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Delivered Impressions:
                    </Typography>
                    <Typography>{formatNumberWithCommas(deliveredImpressions)}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Remaining Daily Impressions:
                    </Typography>
                    <Typography>{formatNumberWithCommas(remainingDailyImpressions)}</Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default PacingDetailTab;
