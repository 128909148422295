import {
    Chip,
    ChipPropsColorOverrides,
    ChipPropsSizeOverrides,
    ChipPropsVariantOverrides,
    Popover,
} from '@mui/material';
import type { OverridableStringUnion } from '@mui/types';

type ChipPopoverProps = {
    children: React.ReactNode;
    chipConfig: {
        icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
        handleEnter: (event: React.MouseEvent<HTMLDivElement>) => void;
        handleLeave: () => void;
        label: React.ReactNode;
        size?: OverridableStringUnion<'small' | 'medium', ChipPropsSizeOverrides> | undefined;
        color?:
            | OverridableStringUnion<
                  'default' | 'warning' | 'primary' | 'secondary' | 'error' | 'info' | 'success',
                  ChipPropsColorOverrides
              >
            | undefined;
        variant?: OverridableStringUnion<'outlined' | 'filled', ChipPropsVariantOverrides> | undefined;
    };
    popoverConfig: {
        popoverId: string | undefined;
        popoverOpen: boolean;
        popoverAnchor: HTMLDivElement | null;
    };
};

const ChipPopover = ({ chipConfig, popoverConfig, children }: ChipPopoverProps) => {
    const { popoverId, popoverOpen, popoverAnchor } = popoverConfig;
    const { handleEnter, handleLeave, label, icon, size, color } = chipConfig;

    return (
        <>
            <Chip
                icon={icon}
                size={size}
                color={color}
                variant='outlined'
                label={label}
                sx={{ cursor: 'pointer', padding: '5px' }}
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
            />
            <Popover
                id={popoverId}
                open={popoverOpen}
                anchorEl={popoverAnchor}
                onClose={handleLeave}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                disableRestoreFocus
                sx={{
                    marginTop: '5px',
                    marginLeft: '5px',
                    pointerEvents: 'none',
                }}
            >
                {children}
            </Popover>
        </>
    );
};

export default ChipPopover;
