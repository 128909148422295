export const monitorPlatformSupport: {
    [rule in MonitorRule]: { [key in Platform]: { supported: boolean } };
} = {
    launchVerification: {
        TTD: { supported: true },
        Adwords: { supported: true },
        Facebook: { supported: true },
        DCM: { supported: true },
    },
    activityTrend: {
        TTD: { supported: true },
        Adwords: { supported: true },
        Facebook: { supported: true },
        DCM: { supported: true },
    },
    deliveryCheck: {
        TTD: { supported: true },
        Adwords: { supported: true },
        Facebook: { supported: true },
        DCM: { supported: true },
    },
    flightPacing: {
        TTD: { supported: true },
        Adwords: { supported: true },
        Facebook: { supported: true },
        DCM: { supported: true },
    },
    marginThreshold: {
        TTD: { supported: true },
        Adwords: { supported: false },
        Facebook: { supported: false },
        DCM: { supported: false },
    },
};
