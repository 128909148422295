import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import { HEADERS } from '../../style/theme';

import brokenIcon from '../../assets/icons8-broken_link.png';
import lockIcon from '../../assets/icons8-lock_2.png';
import loadingGif from '../../assets/loading.gif';

const ErrorImage = styled('img')`
    width: 80px;
    margin-bottom: 24px;
`;

const ErrorWrapper = styled(Stack)`
    background-color: ${common.white};
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 32px 8px 32px 8px;
    padding: 48px 12px 60px;
    overflow: visible;
    box-shadow: 0 1px 2px 0 rgba(127, 127, 127, 0.4);
`;

const ErrorMessage = ({ iconSrc, iconAlt, header, variant, children }) => {
    const notFoundError = {
        header: 'Whoops... page not found',
        iconAlt: 'Broken chain icon',
        iconSrc: brokenIcon,
        name: 'notFound',
    };
    const loadingError = {
        header: 'LOADING ...',
        iconSrc: loadingGif,
        iconAlt: 'Loading icon',
        name: 'loading',
    };
    const noAccessError = {
        iconSrc: lockIcon,
        iconAlt: 'Lock icon',
        header: "You don't have access to that page",
        name: 'noAccess',
    };

    const determineError = () => {
        if (variant) {
            if (variant === notFoundError.name) {
                return notFoundError;
            }
            if (variant === loadingError.name) {
                return loadingError;
            }
            if (variant === noAccessError.name) {
                return noAccessError;
            }
        }
        return 'none';
    };

    if (variant !== 'default') {
        const error = determineError();
        return (
            <ErrorMessage header={error.header} iconSrc={error.iconSrc} iconAlt={error.iconAlt}>
                {children}
            </ErrorMessage>
        );
    }

    return (
        <ErrorWrapper alignItems='center' justifyContent='center'>
            <ErrorImage src={iconSrc} alt={iconAlt} />
            <Typography variant={HEADERS.smallHeader} sx={{ pb: 1 }}>
                {header}
            </Typography>
            <Typography>{children}</Typography>
        </ErrorWrapper>
    );
};

export default ErrorMessage;

ErrorMessage.propTypes = {
    iconSrc: PropTypes.string.isRequired,
    iconAlt: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
