import { CSSProperties } from 'react';
import { theme } from './theme';

export const chartToolTipStyle: CSSProperties = {
    backgroundColor: 'white',
    fontSize: '.9em',
    padding: '8px',
    borderColor: theme.palette.primary.main,
    borderStyle: 'outset',
    borderWidth: '1px',
    lineHeight: '1.5em',
    boxShadow: `1px 1px 1px ${theme.palette.primary.main}`,
    textAlign: 'left',
};
