import { GridFilterModel, GridLinkOperator, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Beenhere, Circle, WarningAmber } from '@mui/icons-material';
import { LicenseInfo } from '@mui/x-license-pro';
import { useEffect, useState } from 'react';
import EventsDataGrid from './EventsDataGrid';
import TableFilter from './TableFilter';
import Env from '../../helpers/Env';
import { generateConfigFilterInputs } from '../../helpers/generateConfigFilterInputs';
import { platformLabels } from '../../constants/platformLabels';
import { phaseLabels } from '../../constants/phaseLabels';
import { checkAdvertiserNoPlacementDataForEvents } from '../../helpers/checkAdvertiserNoPlacementData';
import ChipPopover from './ChipPopover';
import DateRangeFilter from './DateRangeFilter';
import { statusLabels } from '../../constants/statusLabels';
import TableWithFiltersLayout from '../templates/TableWithFiltersLayout';

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

type EventsViewProps = {
    data: FormattedMonitorEvent[];
    advertisers: FollowedAdvertiser[];
};

const EventsView = ({ data, advertisers }: EventsViewProps) => {
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.And,
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        all: true,
        currentPhase: false,
        advertiserName: false,
    });

    // Popover state and anchor
    const [advertiserNoPlacementData, setAdvertiserNoPlacementData] = useState<FollowedAdvertiser[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLeave = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'chip-popover-content' : undefined;

    useEffect(() => {
        const advertisersWithNoActivePlacements = checkAdvertiserNoPlacementDataForEvents(data, advertisers);
        setAdvertiserNoPlacementData(advertisersWithNoActivePlacements);
    }, [data, advertisers]);

    const filterConfigs: TableFilterConfig[] = [
        {
            title: 'Status',
            filterType: 'checkbox',
            filterColumn: 'status',
            filterInputs: generateConfigFilterInputs(data, (item) => item.status || '', 'equals', statusLabels), // passing empty string to satisfy generateConfigFilterInputs
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
        {
            title: 'Phase',
            filterType: 'checkbox',
            filterColumn: 'currentPhase',
            filterInputs: generateConfigFilterInputs(data, (item) => item.currentPhase, 'equals', phaseLabels),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
        {
            title: 'Platform',
            filterType: 'checkbox',
            filterColumn: 'platform',
            filterInputs: generateConfigFilterInputs(data, (item) => item.platform, 'equals', platformLabels),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
        {
            title: 'Monitor',
            filterType: 'checkbox',
            filterColumn: 'monitorName',
            filterInputs: generateConfigFilterInputs(data, (item) => item.monitorName, 'equals'),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
    ];

    const filters = [
        <DateRangeFilter
            key={'date-filter'}
            title='Dates'
            filterModel={{ model: filterModel, setModel: setFilterModel }}
            defaultExpanded
        />,
        ...filterConfigs.map((config, index) => <TableFilter key={index} config={config} />),
    ];

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    maxHeight: '100%',
                    justifyContent: 'flex-start',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '20px 0',
                        marginRight: '20px',
                    }}
                >
                    <Beenhere sx={{ fontSize: '25px' }} />
                    <Typography sx={{ marginLeft: '10px' }} variant='h4'>
                        Event Log
                    </Typography>
                </Box>
                {advertiserNoPlacementData.length !== advertisers.length && advertisers.length > 0 && (
                    <ChipPopover
                        chipConfig={{
                            icon: <WarningAmber />,
                            handleEnter,
                            handleLeave,
                            label: `No Active Placements`,
                            size: 'small',
                            color: 'warning',
                            variant: 'outlined',
                        }}
                        popoverConfig={{
                            popoverId: id,
                            popoverOpen: open,
                            popoverAnchor: anchorEl,
                        }}
                    >
                        <Box sx={{ padding: '10px' }}>
                            <Typography
                                sx={{
                                    padding: '20px 20px 10px',
                                }}
                                variant='subtitle2'
                            >
                                The following advertisers have no active placements
                            </Typography>
                            <List>
                                {advertiserNoPlacementData.map((advertiser) => (
                                    <ListItem key={advertiser.id} dense sx={{ justifyContent: 'center' }}>
                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                            <Circle sx={{ fontSize: '6px', color: 'black' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={advertiser.advertiser.advertiserName} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </ChipPopover>
                )}
            </Box>
            <TableWithFiltersLayout filters={filters}>
                <EventsDataGrid
                    setFilterModel={setFilterModel}
                    filterModel={filterModel}
                    columnVisibilityModel={columnVisibilityModel}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    data={data}
                    advertisers={advertisers}
                />
            </TableWithFiltersLayout>
        </>
    );
};
export default EventsView;
