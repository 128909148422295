import TTDIcon from '../../assets/platform-ttd.svg';
import FacebookIcon from '../../assets/platform-facebook.svg';
import GoogleAdsIcon from '../../assets/platform-google-ads.svg';
import DCMIcon from '../../assets/platform-dcm.svg';

type PlatformIconProps = {
    platform: Platform;
    size?: number;
};

const platformMap = {
    TTD: TTDIcon,
    Facebook: FacebookIcon,
    Adwords: GoogleAdsIcon,
    DCM: DCMIcon,
};

const PlatformIcon = ({ platform, size = 30 }: PlatformIconProps) => (
    <div style={{ width: `${size}px`, height: `${size}px` }}>
        <img src={platformMap[platform]} alt={`${platform} Icon`} />
    </div>
);

export default PlatformIcon;
