import React, { ReactNode, useMemo } from 'react';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { MuiGoodwayTheme } from '@goodwaygroup/ui-ux';

interface GoodwayThemeProviderProps {
    children: ReactNode;
}

const GoodwayThemeProvider: React.FC<GoodwayThemeProviderProps> = ({ children }) => {
    const muiTheme = useMemo(() => createTheme(MuiGoodwayTheme), []);
    const overrideTheme = createTheme({
        ...muiTheme,
        palette: {
            ...muiTheme.palette,
            success: {
                ...muiTheme.palette.success,
                light: '#9af6ca',
                main: '#00B860',
            },
            error: {
                ...muiTheme.palette.error,
                light: '#e8aaaa',
            },
            info: {
                ...muiTheme.palette.info,
                main: '#90caf9',
                light: '#99aed3',
            },
            secondary: {
                ...muiTheme.palette.secondary,
                main: '#d1d5db',
            },
        },
    });

    return (
        <ThemeProvider theme={muiTheme}>
            <ThemeProvider theme={overrideTheme}>{children}</ThemeProvider>
        </ThemeProvider>
    );
};

export default GoodwayThemeProvider;
