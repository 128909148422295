import { Box, Drawer, Grid, Typography } from '@mui/material';
import PlatformIcon from '../atoms/PlatformIcon';

export type DrawerHeaderAdvertiserInfo = Pick<Advertiser, 'advertiserName'>;
export type DrawerHeaderPlacementInfo = Pick<Placement, 'platform' | 'placementName' | 'campaignName'>;

type DetailDrawerProps = {
    children: React.ReactNode;
    advertiser: DrawerHeaderAdvertiserInfo;
    placement: DrawerHeaderPlacementInfo;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
};

const DetailDrawer = ({ children, advertiser, placement, open, setOpen, onClose }: DetailDrawerProps) => {
    const { advertiserName } = advertiser;
    const { platform, placementName, campaignName } = placement;

    return (
        <Drawer
            sx={{ width: 'clamp(250px, 50%, 600px)', padding: '15px' }}
            anchor='right'
            open={open}
            onClose={() => {
                setOpen(false);
                onClose();
            }}
        >
            <Box sx={{ minWidth: 600, maxWidth: 600, padding: '30px', marginTop: '70px', overflowY: 'overlay' }}>
                <Grid container marginBottom={2}>
                    <Grid item xs={11}>
                        <Typography variant='subtitle2' fontWeight={500} fontSize={12}>
                            {advertiserName}
                        </Typography>
                        <Typography variant='h5' fontWeight={'bold'}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>{placementName}</Box>
                        </Typography>
                        <Typography variant='subtitle1'>{campaignName}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <PlatformIcon platform={platform} size={35} />
                    </Grid>
                </Grid>
                {children}
            </Box>
        </Drawer>
    );
};

export default DetailDrawer;
