import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ProtectedApp, ApolloAuthProvider, AuthDefault } from '@goodwaygroup/ui-ux';
import { SnackbarProvider } from 'notistack';
import App from './App';
import Env from './helpers/Env';
import RollbarProvider from './components/atoms/RollbarProvider';
import ApolloWrapper from './components/atoms/ApolloWrapper.jsx';
import { QA_PLATFORM_ACCESS } from './constants/capabilities';
import './index.css';
import ToastStack from './components/atoms/ToastStack';
import { MAX_TOAST_MESSAGES, TOAST_STYLE_OVERRIDES } from './constants/toastNotificationSettings';

const isDev = Env('VITE_NODE_ENV') !== 'staging' && Env('VITE_NODE_ENV') !== 'production';
const preventRedirect = isDev;
const bypassGraphAuth = isDev && Env('VITE_APP_BYPASS_GRAPH_AUTH');

const DUMMY_USER = {
    __typename: 'UserData',
    email: 'jdoe@goodwaygroup.com',
    fullName: 'Jane Doe',
    id: 2,
    isOkta: true,
    landingPage: '/',
    scope: ['Qa-Platform-Access'],
};

AuthDefault({
    bypassEmail: 'sms-team@goodwaygroup.com',
    bypassGraphAuth,
    promptForMissingAuthToken: isDev,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <StrictMode>
        <RollbarProvider>
            <ApolloWrapper>
                {bypassGraphAuth ? (
                    <App user={DUMMY_USER} />
                ) : (
                    <ApolloAuthProvider
                        loginUrl={Env('VITE_APP_LOGIN_API_URL')}
                        preventLoginRedirect={preventRedirect}
                        appName='qa'
                    >
                        <ProtectedApp capability={QA_PLATFORM_ACCESS} render={(user) => <App user={user} />} />{' '}
                    </ApolloAuthProvider>
                )}
            </ApolloWrapper>
            <SnackbarProvider maxSnack={MAX_TOAST_MESSAGES} sx={TOAST_STYLE_OVERRIDES}>
                <ToastStack />
            </SnackbarProvider>
        </RollbarProvider>
    </StrictMode>
);
