import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { GridFilterItem, GridFilterModel, GridLinkOperator } from '@mui/x-data-grid-pro';
import { Badge, Box, Button, Grid } from '@mui/material';

type TableFilterProps = {
    config: TableFilterConfig;
};

export const manageFilters = (
    values: string[],
    column: string,
    operator: string,
    type: string,
    filterModel: { model: GridFilterModel; setModel: React.Dispatch<React.SetStateAction<GridFilterModel>> }
) => {
    const { model, setModel } = filterModel;
    let items: GridFilterItem[] = [...model.items];
    let linkOperator: GridLinkOperator | undefined;

    if (type === 'radio') {
        if (values[0] === '') {
            items = [...items.filter((item) => item.id !== column)];
        } else {
            items = [
                ...items.filter((item) => item.id !== column),
                {
                    id: column,
                    columnField: column,
                    value: values[0],
                    operatorValue: operator,
                },
            ];
        }
    }

    if (type === 'checkbox') {
        items = items.filter((item) => item.id !== `${column}`);
        if (values.length > 0) {
            items.push({
                id: `${column}`,
                columnField: column,
                value: values,
                operatorValue: operator,
            });
        }
    }

    setModel({ items, linkOperator });
};

const TableFilter = ({ config }: TableFilterProps) => {
    const { title, filterType, filterColumn, filterInputs, filterModel, defaultExpanded = false } = config;
    const [selectedRadio, setSelectedRadio] = useState('');
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState(
        Object.fromEntries(filterInputs.map((input) => [input.value, false]))
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = event.target;
        let values: string[] = [];
        let filterOperator = '';

        if (filterType === 'checkbox') {
            setSelectedCheckBoxes((prevState) => ({ ...prevState, [name]: checked }));

            const nameInSelectedCheckedBoxes = Object.keys(selectedCheckBoxes)
                .filter((key) => selectedCheckBoxes[key])
                .includes(name);

            values = [...Object.keys(selectedCheckBoxes).filter((key) => selectedCheckBoxes[key])];

            if (checked && !nameInSelectedCheckedBoxes) {
                values = [...values, name];
            } else if (!checked && nameInSelectedCheckedBoxes) {
                values = values.filter((val) => val !== name);
            }

            filterOperator = 'isAnyOf';
        }

        if (filterType === 'radio') {
            setSelectedRadio(value);
            values = [selectedRadio];
            filterOperator = filterInputs.find((input) => input.value === selectedRadio)?.operator || '';
        }

        manageFilters(values, filterColumn, filterOperator, filterType, filterModel);
    };

    useEffect(() => {
        if (filterType === 'checkbox') {
            setSelectedCheckBoxes((prev) => {
                const selectedValues = filterModel.model.items
                    .filter((item: GridFilterItem) => item.id === filterColumn)
                    .map((item: GridFilterItem) => item.value)
                    .flat();

                return Object.fromEntries(Object.keys(prev).map((key) => [key, selectedValues.includes(key) || false]));
            });
        }
        if (filterType === 'radio') {
            setSelectedRadio(() => {
                const selectedValue = filterModel.model.items
                    .filter((item: GridFilterItem) => item.id === filterColumn)
                    .map((item: GridFilterItem) => item.value)
                    .flat()[0];

                return selectedValue || '';
            });
        }
    }, [filterModel.model.items]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClearFilter = () => {
        if (filterType === 'radio') {
            setSelectedRadio('');
        }

        if (filterType === 'checkbox') {
            setSelectedCheckBoxes(Object.fromEntries(filterInputs.map((input) => [input.value, false])));
        }

        // Clear the applied filters
        manageFilters([], filterColumn, '', filterType, filterModel);
    };

    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            elevation={0}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'min-content',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                '&:before': {
                    display: 'none',
                },
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography>{title}</Typography>
                    <Badge
                        badgeContent={
                            (filterType === 'checkbox' &&
                                Object.values(selectedCheckBoxes).filter((item) => item === true).length) ||
                            (filterType === 'radio' && selectedRadio && 1)
                        }
                        color='primary'
                        invisible={!selectedRadio && !Object.values(selectedCheckBoxes).some((value) => value)}
                        sx={{ marginLeft: '20px' }}
                    />
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction='column' justifyContent='flex-start' gap={1}>
                    <FormControl>
                        {filterType === 'radio' && (
                            <RadioGroup
                                aria-labelledby='demo-controlled-radio-buttons-group'
                                name='controlled-radio-buttons-group'
                                value={selectedRadio}
                                onChange={handleChange}
                            >
                                {filterInputs.map((input) => (
                                    <FormControlLabel
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '14px',
                                            },
                                        }}
                                        key={input.value}
                                        value={input.value}
                                        control={
                                            <Radio
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                }}
                                            />
                                        }
                                        label={input.label}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                        {filterType === 'checkbox' && (
                            <FormGroup>
                                {filterInputs.map((input) => (
                                    <FormControlLabel
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '14px',
                                            },
                                        }}
                                        key={input.value}
                                        control={
                                            <Checkbox
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 14,
                                                    },
                                                }}
                                                checked={selectedCheckBoxes[input.value]}
                                                onChange={handleChange}
                                                name={input.value}
                                            />
                                        }
                                        label={input.label}
                                    />
                                ))}
                            </FormGroup>
                        )}
                    </FormControl>
                    <Button size='small' sx={{ maxWidth: 'max-content' }} onClick={onClearFilter}>
                        Clear Filter
                    </Button>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default TableFilter;
