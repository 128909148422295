import { useMutation, gql } from '@apollo/client';
import { Box, Button, CircularProgress } from '@mui/material';

const FOLLOW_MEDIAOCEAN_ADVERTISER = gql`
    mutation followMediaoceanAdvertiser($advertiserCode: ID!) {
        followMediaoceanAdvertiser(advertiserCode: $advertiserCode) {
            id
            advertiserCode
            advertiserName
            advertiserShortName
            locationCompanyId
            locationName
            isFollowed
        }
    }
`;

const UNFOLLOW_MEDIAOCEAN_ADVERTISER = gql`
    mutation unfollowMediaoceanAdvertiser($advertiserCode: ID!) {
        unfollowMediaoceanAdvertiser(advertiserCode: $advertiserCode) {
            id
            advertiserCode
            advertiserName
            advertiserShortName
            locationCompanyId
            locationName
            isFollowed
        }
    }
`;

type FollowAdvertiserButtonProps = {
    advertiserCode: string | undefined;
    isFollowed: boolean;
};

const FollowAdvertiserButton = ({ isFollowed, advertiserCode }: FollowAdvertiserButtonProps) => {
    const [followAdvertiser, { loading: followAdvertiserLoading }] = useMutation(FOLLOW_MEDIAOCEAN_ADVERTISER, {
        refetchQueries: ['getMyMediaoceanPlacements'],
    });
    const [unfollowAdvertiser, { loading: unfollowAdvertiserLoading }] = useMutation(UNFOLLOW_MEDIAOCEAN_ADVERTISER, {
        refetchQueries: ['getMyMediaoceanPlacements'],
    });

    const handleClick = () => {
        if (followAdvertiserLoading || unfollowAdvertiserLoading) {
            return null;
        }
        if (isFollowed) {
            return unfollowAdvertiser({ variables: { advertiserCode } });
        }
        return followAdvertiser({ variables: { advertiserCode } });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                minWidth: '100px',
            }}
        >
            {followAdvertiserLoading || unfollowAdvertiserLoading ? (
                <CircularProgress size={30} />
            ) : (
                <Button onClick={handleClick} variant={isFollowed ? 'outlined' : 'contained'} sx={{ width: '100%' }}>
                    {isFollowed ? 'Unfollow' : 'Follow'}
                </Button>
            )}
        </Box>
    );
};

export default FollowAdvertiserButton;
