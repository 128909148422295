const innerRGB = {
    darkYellow: '251, 173, 25',
};

const colors = {
    // Official Brand Colors
    midnightBlue: 'rgb(26, 29, 49)',
    blue: 'rgb(0, 125, 198)',
    yellow: 'rgb(252, 209, 25)',
    lightGrey: 'rgb(241, 241, 241)',
    lightLightGrey: 'rgb(248, 248, 248)',

    // Other colors
    lightBlue: 'rgb(129, 191, 220)',
    darkBlue: 'rgb(11, 52, 70)',
    lightYellow: 'rgb(255, 230, 35)',
    darkYellow: `rgb(${innerRGB.darkYellow})`,
    red: 'rgb(229, 23, 37)',
    grey: 'rgb(169, 169, 169)',
    darkGrey: 'rgb(101, 97, 88)',

    // Colors for exportBillingTable
    tableHeaderYellow: 'FCD119',
    tableHeaderGoodwayBlue: '007DC6',
    tableHeaderDarkMidnightBlue: '1A1D31',
    tableHeaderLightMidnightBlue: '404877',
    tableHeaderTextWhite: 'FFFFFF',
    tableHeaderTextBlack: '000000',

    tableColumnGray: 'F1F1F1',
    tableColumnGreen: 'E4EFDC',
    tableColumnBlue: 'ECF4F9',
    tableColumnYellow: 'FDFBED',
    tableColumnOrange: 'FFF7E4',
    tableColumnRed: 'F9EDEC',
    tableColumnTextRed: 'C81420',

    // Colors for Billing Manager row highlight
    darkYellowWith25Opacity: `rgba(${innerRGB.darkYellow}, 0.25)`,
    darkYellowWith35Opacity: `rgba(${innerRGB.darkYellow}, 0.35)`,

    whiteWith15Opacity: `rgba(255, 255, 255, .15)`,
    whiteWith75Opacity: `rgba(255, 255, 255, .75)`,
};

export default colors;
