import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Box, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import dayjs from 'dayjs';

type MonitorPopoverProps = {
    status: LegacyMonitorStatus;
    name: string;
    lastRunDate: string;
};

const MonitorPopover = ({ status, name, lastRunDate }: MonitorPopoverProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // TODO: Fix nested Typography components at the bottom of this component, this throws warnings in console
    return (
        <>
            <Box
                sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {status === 'PASS' && <CheckCircleIcon sx={{ color: theme.palette.success.main, fontSize: '30px' }} />}
                {status === 'FAIL' && <CancelIcon sx={{ color: theme.palette.error.main, fontSize: '30px' }} />}
                {status === 'INSUFFICIENT DATA' && <ContrastOutlinedIcon sx={{ color: 'grey', fontSize: '30px' }} />}
                {status === 'WARNING' && <ErrorIcon sx={{ color: theme.palette.warning.main, fontSize: '30px' }} />}
                {!status && <CircleOutlinedIcon sx={{ color: 'grey', fontSize: '30px' }} />}
            </Box>
            <Popover
                id='mouse-over-popover'
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ padding: '20px' }}>
                    <Typography variant='h6' sx={{ fontSize: '16px', marginBottom: '10px' }}>
                        {status ? name : 'Not Available'}
                    </Typography>
                    <Typography variant='body2' sx={{ fontWeight: 700, display: 'flex', flexDirection: 'flex-row' }}>
                        Status:{' '}
                        <Typography variant='body2' sx={{ marginLeft: '5px' }}>
                            {status || 'Monitor data not available.'}
                        </Typography>
                    </Typography>
                    <Typography variant='body2' sx={{ fontWeight: 700, display: 'flex', flexDirection: 'flex-row' }}>
                        Last Run Date:{' '}
                        <Typography variant='body2' sx={{ marginLeft: '5px' }}>
                            {dayjs(lastRunDate).format('MM/DD/YYYY') || 'N/A'}
                        </Typography>
                    </Typography>
                </Box>
            </Popover>
        </>
    );
};

export default MonitorPopover;
