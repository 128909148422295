export const pascalCaseWord = (word: string): string => word[0].toUpperCase() + word.substring(1);
export const pascalCaseWords = (words: string[]): string[] => words.map((word) => pascalCaseWord(word));
export const camelCaseWord = (words: string): string =>
    words
        .split(' ')
        .map((word, i) => (i === 0 ? word.toLowerCase() : word))
        .join('');

export function formatToUSCurrency(amount: number): string {
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function formatNumberWithCommas(number: number): string {
    return number.toLocaleString('en-US');
}
