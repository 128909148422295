import React, { useState, useEffect } from 'react';
import { Box, Typography, Tab, Tabs, Grid, LinearProgress, Divider } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import InfoIcon from '@mui/icons-material/Info';
import { GridRowParams } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import PacingDetailTab from './PacingDetailTab';
import PacingHistoryTab from './PacingHistoryTab';
import AboutMonitorTab from './AboutMonitorTab';
import DetailDrawer from '../templates/DetailDrawerLayout';
import type { DrawerHeaderAdvertiserInfo, DrawerHeaderPlacementInfo } from '../templates/DetailDrawerLayout';

type PacingDrawerProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    rowParams: GridRowParams;
};

const PacingDrawer = ({ open, setOpen, rowParams }: PacingDrawerProps) => {
    const pacingTabMap = {
        PACING_DETAILS_TAB: 0,
        PACING_HISTORY_TAB: 1,
        ABOUT_MONITOR_TAB: 2,
    };
    const [currentPacingTabIndex, setCurrentPacingTabIndex] = useState(Object.values(pacingTabMap)[0]);

    const { row } = rowParams;
    const {
        advertiser,
        placement,
        flightPercentage,
    }: { advertiser: Advertiser; placement: Placement; flightPercentage: number } = row;
    const { startDate, endDate } = placement;

    const changeTab = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentPacingTabIndex(newValue);
    };

    const advertiserHeaderInfo: DrawerHeaderAdvertiserInfo = {
        advertiserName: advertiser.advertiserName,
    };

    const placementHeaderInfo: DrawerHeaderPlacementInfo = {
        platform: placement.platform,
        placementName: placement.placementName,
        campaignName: placement.campaignName,
    };

    useEffect(() => {
        if (open) {
            setCurrentPacingTabIndex(pacingTabMap.PACING_DETAILS_TAB);
        }
    }, [open, pacingTabMap.PACING_DETAILS_TAB]);

    return (
        <DetailDrawer
            open={open}
            setOpen={setOpen}
            advertiser={advertiserHeaderInfo}
            placement={placementHeaderInfo}
            onClose={() => {
                setOpen(false);
            }}
        >
            <Typography variant='body2' fontWeight={500} marginBottom={1}>
                Flight Progress
            </Typography>
            <Grid container flexDirection='row' alignItems='center' columnGap={2} marginBottom={2}>
                <Grid item>
                    <Typography variant='body1'>{dayjs(startDate).format('MM/DD/YYYY')}</Typography>
                </Grid>
                <Grid item flexGrow='1'>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress
                            variant='determinate'
                            value={flightPercentage}
                            sx={{ height: 8, borderRadius: 2 }}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant='body1'>{dayjs(endDate).format('MM/DD/YYYY')}</Typography>
                </Grid>
            </Grid>
            <Divider sx={{ marginBottom: '10px' }} />
            <Tabs sx={{ margin: '10px 0 20px 0' }} value={currentPacingTabIndex} onChange={changeTab}>
                <Tab label='Check Details' icon={<ChecklistRtlIcon />} />
                <Tab label='Placement History' icon={<QueryStatsIcon />} />
                <Tab label='How it Works' icon={<InfoIcon />} />
            </Tabs>
            {currentPacingTabIndex === pacingTabMap.PACING_DETAILS_TAB && (
                <PacingDetailTab rowParams={rowParams} index={pacingTabMap.PACING_DETAILS_TAB} />
            )}
            {currentPacingTabIndex === pacingTabMap.PACING_HISTORY_TAB && (
                <PacingHistoryTab
                    placementId={rowParams.row.placement.id}
                    startDate={rowParams.row.placement.startDate}
                    endDate={rowParams.row.placement.endDate}
                />
            )}
            {currentPacingTabIndex === pacingTabMap.ABOUT_MONITOR_TAB && <AboutMonitorTab monitorName='flightPacing' />}
        </DetailDrawer>
    );
};

export default PacingDrawer;
