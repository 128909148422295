import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { theme } from '../../style/theme';

type ActivityHistoryChartProps = {
    data: ActivityHistory[];
};

type CustomTooltipProps = {
    active: boolean;
    payload: { payload: ActivityHistory }[];
};

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
        const { activities, message, date } = payload[0].payload;

        return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: '8px',
                    borderColor: theme.palette.primary.main,
                    borderStyle: 'outset',
                    borderWidth: '1px',
                    lineHeight: '1.5em',
                    boxShadow: `1px 1px 1px ${theme.palette.primary.main}`,
                    color: '#212121',
                    minWidth: '175px',
                }}
            >
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    {date}
                </Typography>
                <Box display='flex' flexDirection='row' gap='5px'>
                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                        Activities:
                    </Typography>
                    <Typography variant='body2'>{activities}</Typography>
                </Box>
                {activities === 0 && (
                    <Box display='flex' flexDirection='row' gap='5px'>
                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                            Message:
                        </Typography>
                        <Typography variant='body2'>{message}</Typography>
                    </Box>
                )}
            </Box>
        );
    }

    return null;
};
const ActivityHistoryChart = ({ data }: ActivityHistoryChartProps) => (
    <ResponsiveContainer width='100%' height='100%'>
        <BarChart data={data} barCategoryGap='15%' style={{ fontFamily: 'Roboto', fontSize: '12px' }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date' angle={-45} textAnchor='end' height={80} />
            <YAxis />
            <Tooltip content={<CustomTooltip active={false} payload={[]} />} />
            <Bar dataKey='activities' fill={theme.palette.primary.main} />
        </BarChart>
    </ResponsiveContainer>
);

export default ActivityHistoryChart;
