import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as FullStory from '@fullstory/browser';
import GoodwayThemeProvider from './components/atoms/GoodwayThemeProvider.tsx';
import PageLayout from './components/templates/PageLayout';
import NotFound from './components/atoms/NotFound';
import AdvertisersPage from './components/pages/AdvertisersPage.tsx';
import AdvertiserPlacementsPage from './components/pages/AdvertiserPlacementsPage.tsx';
import MyPlacementsPage from './components/pages/MyPlacementsPage.tsx';
import EventsPage from './components/pages/EventsPage.tsx';
import PacingPage from './components/pages/PacingPage.tsx';
import { AppContextProvider } from './components/context/AppContext';
import Env from './helpers/Env';

FullStory.init({
    orgId: Env('VITE_APP_FULLSTORY_ORG_ID'),
    devMode: Env('VITE_NODE_ENV') !== 'production',
});

const App = ({ user }) => {
    FullStory.setUserVars({
        email: user.email,
        displayName: user.fullName,
        userId_int: user.id,
        isOkta_bool: user.isOkta,
    });

    const GET_USER_DATA = gql`
        query getUserData {
            userData @rest(type: "UserData", path: "/v1/users/me", method: "GET") {
                email
                fullName
                id
                isOkta
                landingPage
                scope
            }
        }
    `;

    const { error, data } = useQuery(GET_USER_DATA, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (error) {
            const fromGL = encodeURIComponent(`/qa`);
            let loginApiUrl = Env('VITE_APP_LOGIN_API_URL');
            loginApiUrl = loginApiUrl.replace('{from}', fromGL);
            window.location.href = loginApiUrl;
        }
    }, [error, data]);

    return (
        <div className='App'>
            <GoodwayThemeProvider>
                <BrowserRouter>
                    <AppContextProvider>
                        <Routes>
                            <Route path='/' element={<PageLayout user={user} />}>
                                <Route index element={<MyPlacementsPage user={user} />} />
                                <Route path='/advertisers/:advertiserCode' element={<AdvertiserPlacementsPage />} />
                                <Route path='/advertisers' element={<AdvertisersPage />} />
                                <Route path='/events' element={<EventsPage />} />
                                <Route path='/pacing' element={<PacingPage />} />
                                <Route path='*' element={<NotFound />} />
                            </Route>
                        </Routes>
                    </AppContextProvider>
                </BrowserRouter>
            </GoodwayThemeProvider>
        </div>
    );
};
App.propTypes = {
    user: PropTypes.object.isRequired,
};

export default App;
