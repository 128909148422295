import React, { useState } from 'react';
import {
    DataGridPro,
    GridColDef,
    GridRenderCellParams,
    GridFilterModel,
    getGridDateOperators,
    GridColumnVisibilityModel,
    GridRowParams,
    GridToolbar,
} from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import PlatformIcon from '../atoms/PlatformIcon';
import Env from '../../helpers/Env';
import { checkAdvertiserNoPlacementDataForEvents } from '../../helpers/checkAdvertiserNoPlacementData';
import MonitorDetailDrawer from './MonitorDetailDrawer';
import { colorMap } from '../../constants/colorMap';
import { dateFilterOperators } from '../../operators/dateFilterOperators';
import { statusLabels } from '../../constants/statusLabels';

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

type EventDataGridProps = {
    data: FormattedMonitorEvent[];
    advertisers: FollowedAdvertiser[];
    filterModel: GridFilterModel;
    setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
    columnVisibilityModel: GridColumnVisibilityModel;
    setColumnVisibilityModel: React.Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
};

const EventsDataGrid = ({
    data,
    advertisers,
    filterModel,
    setFilterModel,
    columnVisibilityModel,
    setColumnVisibilityModel,
}: EventDataGridProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [rowParams, setRowParams] = useState<GridRowParams | null>(null);
    const navigate = useNavigate();
    const defaultDateOperators = getGridDateOperators().filter(
        (op) => op.value !== 'isEmpty' && op.value !== 'isNotEmpty' && op.value !== 'not'
    );

    const columns: GridColDef[] = [
        {
            headerName: 'Date',
            field: 'checkDate',
            type: 'date',
            filterOperators: [...defaultDateOperators, ...dateFilterOperators],
        },
        {
            headerName: 'Status',
            headerAlign: 'center',
            field: 'status',
            minWidth: 150,
            renderCell: (props: GridRenderCellParams) => (
                <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', width: '100%' }}>
                    <Chip
                        label={statusLabels[props?.value as PrismaMonitorStatus]}
                        color={colorMap[props?.value as keyof typeof Chip]}
                        sx={{ minWidth: '85px' }}
                        size='small'
                    />
                </Box>
            ),
        },
        {
            headerName: 'Monitor',
            field: 'monitorName',
            valueGetter: (params) => params.row.monitorName,
            minWidth: 150,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Platform',
            headerAlign: 'center',
            field: 'platform',
            renderCell: (props: GridRenderCellParams) => (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <PlatformIcon platform={props?.value} size={30} />
                </Box>
            ),
        },
        {
            headerName: 'Advertiser',
            field: 'advertiserName',
            valueGetter: (params) => params.row.advertiserName,
            minWidth: 250,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Campaign',
            field: 'campaignName',
            valueGetter: (params) => params.row.campaignName,
            minWidth: 400,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Placement Name',
            field: 'placementName',
            minWidth: 300,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Phase',
            field: 'currentPhase',
            valueGetter: (params) => params.row.currentPhase,
            minWidth: 100,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Reason',
            field: 'reason',
            valueGetter: (params) => params.row.message,
            minWidth: 400,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
    ];

    const handleRowClick = (params: GridRowParams) => {
        setRowParams(params);
        setIsOpen(true);
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: 'calc(100% + 40px)',
                maxHeight: '100%',
                justifyContent: 'flex-start',
                '& .qa-datagrid-monitor--cell': {
                    padding: ' 0 !important',
                    margin: 0,
                },
                width: '100%',
            }}
        >
            <DataGridPro
                sx={{
                    '& .MuiDataGrid-virtualScroller': {
                        pointerEvents: !data.length ? 'none' : 'all',
                    },
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                    },
                }}
                density='compact'
                disableSelectionOnClick
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'checkDate', sort: 'desc' }],
                    },
                }}
                rows={data}
                columns={columns}
                getRowId={(row) => row.id}
                getRowClassName={() => 'clickable-row'}
                rowHeight={90}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        printOptions: { disableToolbarButton: true },
                    },
                }}
                filterModel={filterModel}
                onFilterModelChange={(prev) => setFilterModel(prev)}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                components={{
                    Toolbar: GridToolbar,
                    NoRowsOverlay: () => {
                        const noPlacementAdvertisers = checkAdvertiserNoPlacementDataForEvents(data, advertisers);

                        if (noPlacementAdvertisers.length === advertisers.length && advertisers.length > 0) {
                            return (
                                <Stack height='100%' alignItems='center' justifyContent='center'>
                                    <Typography sx={{ marginBottom: '10px' }} variant='h5'>
                                        No Active Placements
                                    </Typography>
                                    <Typography variant='subtitle1' sx={{ marginBottom: '10px' }}>
                                        The following advertisers have no active placements
                                    </Typography>
                                    <Button
                                        variant='contained'
                                        sx={{ minWidth: '100px' }}
                                        onClick={() => navigate('/advertisers')}
                                    >
                                        Go To Advertisers
                                    </Button>
                                </Stack>
                            );
                        }
                        return (
                            <Stack height='100%' alignItems='center' justifyContent='center'>
                                <Typography sx={{ marginBottom: '10px' }} variant='h5'>
                                    Follow Advertisers To View Campaigns/Placements
                                </Typography>
                                <Typography variant='body2' sx={{ marginBottom: '20px' }}>
                                    Placements from advertisers you follow will show here
                                </Typography>
                                <Button
                                    variant='contained'
                                    sx={{ minWidth: '100px' }}
                                    onClick={() => navigate('/advertisers')}
                                >
                                    Go To Advertisers
                                </Button>
                            </Stack>
                        );
                    },
                }}
                onRowClick={(params) => {
                    handleRowClick(params);
                }}
            />
            {rowParams && <MonitorDetailDrawer open={isOpen} setOpen={setIsOpen} rowParams={rowParams} />}
        </Box>
    );
};

export default EventsDataGrid;
