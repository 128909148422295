import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { monitorDescriptions } from '../../constants/monitorDescriptions';
import { monitorPlatformSupport } from '../../constants/monitorPlatformSupport';
import PlatformIcon from '../atoms/PlatformIcon';

type AboutMonitorTabProps = {
    monitorName: MonitorRule;
};

const AboutMonitorTab = ({ monitorName }: AboutMonitorTabProps) => (
    <>
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Typography
                    variant='body1'
                    dangerouslySetInnerHTML={{
                        __html: monitorDescriptions[monitorName].description,
                    }}
                />
                <Typography variant='subtitle1'>Supported Platforms</Typography>
                <Grid container direction='row' spacing={2}>
                    {Object.keys(monitorPlatformSupport[monitorName]).map((key) => {
                        const platformName = key as Platform;
                        const isSupported = monitorPlatformSupport[monitorName][platformName].supported;

                        return isSupported ? (
                            <Grid item key={platformName}>
                                <PlatformIcon platform={platformName} size={25} />
                            </Grid>
                        ) : null;
                    })}
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant='subtitle1'>Example</Typography>
                <Box sx={{ padding: 3, backgroundColor: '#E0E0E0' }}>
                    <Typography
                        variant='body2'
                        dangerouslySetInnerHTML={{
                            __html: monitorDescriptions[monitorName].example,
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    </>
);

export default AboutMonitorTab;
