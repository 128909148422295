import { SEVERITY } from './constants/toastNotificationSettings';

export const CUSTOM_EVENTS = {
    TOAST: 'toast-notification',
};

export function subscribe(eventName: string, listener: (event: any) => void) {
    document.addEventListener(eventName, listener);
}

export function unsubscribe(eventName: string, listener: (event: any) => void) {
    document.removeEventListener(eventName, listener);
}

type PublishData = {
    message: string;
    severity?: (typeof SEVERITY)[keyof typeof SEVERITY];
    action?: {
        label: string;
        onClick: () => void;
    };
};

export function publish(eventName: string, data: PublishData) {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
}

export function addToast(
    message: string,
    options: { severity: string; action?: { label: string; onClick: () => void } }
) {
    const { severity, action, ...rest } = options;

    publish(CUSTOM_EVENTS.TOAST, {
        message,
        severity,
        action,
        ...rest,
    });
}
