import compact from 'lodash/compact';
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
import LoadingBlock from '../atoms/LoadingBlock';
import EventsView from '../molecules/EventsView';
import { camelCaseWord } from '../../helpers/formatters';

const GET_FOLLOWED_ADVERTISERS = gql`
    query getFollowedAdvertisers {
        getFollowedAdvertisers {
            id
            advertiser {
                id
                advertiserName
            }
        }
    }
`;

const GET_EVENT_DATA = gql`
    query getEventDataForPlacements {
        getEventDataForPlacements {
            checkDate
            executionDate
            id
            message
            meta
            monitor {
                description
                id
                name
                phase
            }
            status
            placement {
                campaignName
                placementId
                placementName
                currentPhase
                platform
                advertiserName
                buyType
                startDate
                endDate
            }
        }
    }
`;

const EventsPage = () => {
    const { loading: eventsLoading, data: eventData } = useQuery(GET_EVENT_DATA, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });
    const { loading: advertisersLoading, data: advertisersData } = useQuery(GET_FOLLOWED_ADVERTISERS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const events: UnformattedMonitorEvent[] = compact(eventData?.getEventDataForPlacements || []);
    const advertisers: FollowedAdvertiser[] = compact(advertisersData?.getFollowedAdvertisers || []);

    const rows = useMemo<FormattedMonitorEvent[]>(
        () =>
            events.map(
                ({
                    id,
                    checkDate,
                    executionDate,
                    message,
                    meta,
                    status,
                    placement: {
                        campaignName,
                        placementName,
                        currentPhase,
                        platform,
                        advertiserName,
                        buyType,
                        placementId,
                        startDate,
                        endDate,
                    },
                    monitor: { description: monitorDescription, id: monitorId, name: monitorName, phase: monitorPhase },
                }) => ({
                    id,
                    checkDate,
                    executionDate,
                    message,
                    meta,
                    status,
                    campaignName,
                    placementName,
                    platform,
                    advertiserName,
                    buyType,
                    currentPhase,
                    monitorDescription,
                    monitorId,
                    monitorName,
                    monitorFieldName: camelCaseWord(monitorName),
                    monitorPhase,
                    placementId,
                    startDate,
                    endDate,
                })
            ),
        [events]
    );

    if (eventsLoading || advertisersLoading) return <LoadingBlock message='Gathering your Campaigns. Be right back!' />;
    return <EventsView data={rows} advertisers={advertisers} />;
};

export default EventsPage;
