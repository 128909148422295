import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { phaseGroupings } from '../constants/phaseGroupings';

export const checkMonitorStatus = (params: GridRenderCellParams) => {
    const monitorCheck = params.field as MonitorRule;
    const monitorPhase = phaseGroupings.find((phase) => phase.monitors.includes(monitorCheck));
    const { currentPhase } = params.row;
    const monitorIndex = phaseGroupings.findIndex((phase) => phase.phase === monitorPhase?.phase);
    const currentMonitorIndex = phaseGroupings.findIndex((phase) => phase.phase === currentPhase);

    if (monitorPhase?.phase !== currentPhase && currentMonitorIndex > monitorIndex) {
        return 'DONE';
    }

    return params.value?.status;
};
