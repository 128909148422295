import { useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { subscribe, unsubscribe, CUSTOM_EVENTS } from '../../event';
import { createCloseButtonX } from '../../helpers/toastActions';
import { DEFAULT_SEVERITY, DEFAULT_TOAST_DURATION, SEVERITY } from '../../constants/toastNotificationSettings';

// set specific settings for different toast severities
const severityMappings = {
    [SEVERITY.SUCCESS]: {
        autoHideDuration: 2000,
    },
};

const ToastStack = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showNewToast = useCallback(
        (event) => {
            const { message, severity, action, ...rest } = event.detail;
            const toastSeverity = severity || DEFAULT_SEVERITY;
            const toastAction = action || createCloseButtonX;
            const severityMapping = severityMappings[toastSeverity] || {};

            enqueueSnackbar(message, {
                action: toastAction(closeSnackbar),
                autoHideDuration: DEFAULT_TOAST_DURATION,
                persist: false,
                preventDuplicate: true,
                variant: toastSeverity || DEFAULT_SEVERITY,
                ...severityMapping,
                ...rest,
            });
        },
        [closeSnackbar, enqueueSnackbar]
    );

    useEffect(() => {
        subscribe(CUSTOM_EVENTS.TOAST, showNewToast);

        // Return statement to clean up event listener
        return () => unsubscribe(CUSTOM_EVENTS.TOAST, showNewToast);
    }, [showNewToast]);
};

export default ToastStack;
