import PropTypes from 'prop-types';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client';
import { useRollbar } from '@rollbar/react';
import { loginAfterAuthError, g90LoginLink, appLink } from '@goodwaygroup/ui-ux';
import Env from '../../helpers/Env';
import { addToast } from '../../event';
import { SEVERITY } from '../../constants/toastNotificationSettings';

const ApolloWrapper = ({ children, loginUrl, preventLoginRedirect }) => {
    const rollbar = useRollbar();

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        // Create Rollbar Error
        const error = graphQLErrors || networkError;
        const errorType = graphQLErrors ? 'GraphQL Error' : 'Network Error';

        // Dont send rollbar 401 unathenticated errors.
        if (!(networkError && networkError.statusCode === 401)) {
            rollbar.error(errorType, error);
        }

        // eslint-disable-next-line no-console
        console.error(errorType, error);
        if (errorType === 'GraphQL Error') {
            error.forEach(({ message }) => {
                addToast(message, {
                    severity: SEVERITY.ERROR,
                    persist: true,
                });
            });
        } else {
            addToast('Something went wrong. We have been notified and are looking into it.', {
                severity: SEVERITY.ERROR,
                persist: true,
            });
        }
    });

    const cache = new InMemoryCache();
    const client = new ApolloClient({
        link: ApolloLink.from([
            errorLink,
            loginAfterAuthError(loginUrl, preventLoginRedirect, () => client),
            g90LoginLink(Env('VITE_APP_API_LOOKING_GLASS_URL')),
            appLink(Env('VITE_APP_API_URL')),
        ]),
        cache,
        connectToDevTools: Env('VITE_NODE_ENV') === 'development',
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

ApolloWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    loginUrl: PropTypes.string.isRequired,
    preventLoginRedirect: PropTypes.bool.isRequired,
};

export default ApolloWrapper;
