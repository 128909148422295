import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid, Button, Badge, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LocalizationProvider, DateRangePicker, SingleInputDateRangeField, DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { GridFilterModel, GridLinkOperator } from '@mui/x-data-grid-pro';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

type DateRangeFilterProps = {
    title: string;
    defaultExpanded?: boolean;
    filterModel: { model: GridFilterModel; setModel: React.Dispatch<React.SetStateAction<GridFilterModel>> };
};

const DateRangeFilter = ({ title, defaultExpanded, filterModel }: DateRangeFilterProps) => {
    const { model, setModel } = filterModel;
    const [filterDates, setFilterDates] = useState<DateRange<Dayjs>>([null, null]);

    const handleDateFilterChange = (newDates: DateRange<Dayjs>) => {
        const startDate = newDates[0]?.format('YYYY-MM-DD');
        const endDate = newDates[1]?.format('YYYY-MM-DD');

        setFilterDates(newDates);
        setModel({
            items: [
                ...model.items.filter((item) => item.id !== 'startDate' && item.id !== 'endDate'),
                {
                    id: 'startDate',
                    columnField: 'checkDate',
                    value: startDate || null,
                    operatorValue: 'onOrAfter',
                },
                {
                    id: 'endDate',
                    columnField: 'checkDate',
                    value: endDate || null,
                    operatorValue: 'onOrBefore',
                },
            ],
            linkOperator: GridLinkOperator.And,
        });
    };

    useEffect(() => {
        if (model.items.filter((item) => item.id === 'startDate').length === 0) {
            setFilterDates((prev) => [null, prev[1]]);
        }

        if (model.items.filter((item) => item.id === 'endDate').length === 0) {
            setFilterDates((prev) => [prev[0], null]);
        }
    }, [model.items]);

    const onClearFilter = () => {
        setFilterDates([null, null]);

        setModel({
            items: [...model.items.filter((item) => item.id !== 'startDate' && item.id !== 'endDate')],
            linkOperator: GridLinkOperator.And,
        });
    };

    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            elevation={0}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'min-content',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                '&:before': {
                    display: 'none',
                },
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography>{title}</Typography>
                    <Badge
                        badgeContent={1}
                        color='primary'
                        invisible={filterDates[0] === null && filterDates[1] === null}
                        sx={{ marginLeft: '20px' }}
                    />
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction='column' justifyContent='flex-start' gap={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            slots={{ field: SingleInputDateRangeField }}
                            slotProps={{
                                textField: { inputProps: { placeholder: 'Select a date range' } },
                            }}
                            value={filterDates}
                            onChange={(newDates) => handleDateFilterChange(newDates)}
                            sx={{
                                '&& .MuiInputBase-input': {
                                    fontSize: '0.75em',
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <Button size='small' sx={{ maxWidth: 'max-content' }} onClick={onClearFilter}>
                        Clear Filter
                    </Button>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default DateRangeFilter;
