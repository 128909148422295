import React from 'react';
import { Grid, Alert, Stack, Typography, Chip, Box } from '@mui/material';
import ReactJson from '@microlink/react-json-view';
import dayjs from 'dayjs';
import { platformLabels } from '../../constants/platformLabels';
import { colorMap } from '../../constants/colorMap';

type CheckDetailsTabProps = {
    advertiserName: string;
    buyType: string;
    checkDate: string;
    executionDate: string;
    message: string;
    meta: string;
    monitorDisplayName: string;
    placementId: string;
    placementName: string;
    platform: Platform;
    status: string;
};

const CheckDetailsTab = ({
    advertiserName,
    buyType,
    checkDate,
    executionDate,
    monitorDisplayName,
    message,
    meta,
    placementId,
    placementName,
    platform,
    status,
}: CheckDetailsTabProps) => {
    const formattedMeta = JSON.parse(meta);
    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Alert severity={colorMap[status as keyof typeof Alert]}>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1' component='span'>
                        {message}
                    </Typography>
                </Alert>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Monitor:
                    </Typography>
                    <Typography>{monitorDisplayName}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Status:
                    </Typography>
                    <Chip
                        label={status as keyof typeof Alert}
                        color={colorMap[status as keyof typeof Alert]}
                        sx={{ minWidth: '100px' }}
                    />
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Placement ID:
                    </Typography>
                    <Typography>{placementId}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Placement:
                    </Typography>
                    <Typography>{placementName}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Execution Date:
                    </Typography>
                    <Typography>{dayjs(executionDate).format('MM/DD/YYYY')}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Check Date:
                    </Typography>
                    <Typography>{dayjs(checkDate).format('MM/DD/YYYY')}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Advertiser:
                    </Typography>
                    <Typography>{advertiserName}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Platform:
                    </Typography>
                    <Typography>{platformLabels[platform as Platform]}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Buy Type:
                    </Typography>
                    <Typography>{buyType}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction='column' spacing={2}>
                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                        Monitor Data:
                    </Typography>
                    <Box>
                        <ReactJson src={formattedMeta} name={false} style={{ fontSize: '14px' }} />
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CheckDetailsTab;
