import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';

const NotFound = () => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
        }}
    >
        <h2>Sorry! There is nothing at this URL.</h2>
        <p>
            <Link to='/'>Return to home</Link>
        </p>
    </Box>
);

export default NotFound;
