import { GridFilterModel, GridLinkOperator, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { Speed } from '@mui/icons-material';
import { LicenseInfo } from '@mui/x-license-pro';
import { useState } from 'react';
import { generateConfigFilterInputs } from '../../helpers/generateConfigFilterInputs';
import PacingDataGrid from './PacingDataGrid';
import { platformLabels } from '../../constants/platformLabels';
import Env from '../../helpers/Env';
import { statusLabels } from '../../constants/statusLabels';
import ViewHeader from '../atoms/ViewHeader';
import TableWithFiltersLayout from '../templates/TableWithFiltersLayout';
import TableFilter from './TableFilter';

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

type PacingViewProps = {
    data: PacingData[];
};

const PacingView = ({ data }: PacingViewProps) => {
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.And,
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        all: true,
        advertiserName: false,
        pacingType: false,
        status: false,
    });

    const filterConfigs: TableFilterConfig[] = [
        {
            title: 'Advertisers',
            filterType: 'checkbox',
            filterColumn: 'advertiserName',
            filterInputs: generateConfigFilterInputs(data, (item) => item.advertiser.advertiserName, 'equals'),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
            defaultExpanded: true,
        },
        {
            title: 'Platform',
            filterType: 'checkbox',
            filterColumn: 'platform',
            filterInputs: generateConfigFilterInputs(data, (item) => item.placement.platform, 'equals', platformLabels),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
        {
            title: 'Status',
            filterType: 'checkbox',
            filterColumn: 'status',
            filterInputs: generateConfigFilterInputs(data, (item) => item.status, 'equals', statusLabels),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
    ];

    const filters = [...filterConfigs.map((config, index) => <TableFilter key={index} config={config} />)];

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    maxHeight: '100%',
                    justifyContent: 'flex-start',
                }}
            >
                <ViewHeader title='Pacing Insights' icon={<Speed />} />
            </Box>
            <TableWithFiltersLayout filters={filters}>
                <PacingDataGrid
                    setFilterModel={setFilterModel}
                    filterModel={filterModel}
                    columnVisibilityModel={columnVisibilityModel}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    data={data}
                />
            </TableWithFiltersLayout>
        </>
    );
};
export default PacingView;
