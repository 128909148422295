import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { Business } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import Env from '../../helpers/Env';
import ViewHeader from '../atoms/ViewHeader';

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

type AdvertiserViewProps = {
    rows: Advertiser[];
    columns: GridColDef[];
};

const AdvertiserView = ({ columns = [], rows = [] }: AdvertiserViewProps) => {
    const navigate = useNavigate();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <ViewHeader title='Follow Advertisers' icon={<Business />} />
                <Button variant='text' onClick={() => navigate('/')}>
                    View Latest Smartguard Checks
                </Button>
            </Box>

            <DataGridPro
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'advertiserName', sort: 'asc' }],
                    },
                }}
                rows={rows}
                columns={columns}
                getRowId={(row) => row.advertiserCode}
                components={{
                    Toolbar: GridToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </>
    );
};
export default AdvertiserView;
