declare global {
    interface Window {
        goodway: any;
    }
}

export default (key: any) => {
    if (typeof window !== 'undefined' && window.goodway) {
        return window.goodway[key];
    }
    const value = import.meta.env[key];
    return value;
};
