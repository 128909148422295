import { useQuery, gql } from '@apollo/client';
import compact from 'lodash/compact';
import LoadingBlock from '../atoms/LoadingBlock';
import PacingView from '../molecules/PacingView';

const GET_MY_PACING_DATA = gql`
    query getMyPacingData {
        getMyPacingData {
            id
            executionDate
            checkDate
            pacingType
            plannedAmount
            deliveredAmount
            deliveredImpressions
            remainingDailyAmount
            remainingDailyImpressions
            latestDateDeliveredAmount
            flightPercentage
            pacingPercentage
            status
            message
            placement {
                campaignName
                id
                placementId
                placementName
                platform
                startDate
                endDate
            }
            advertiser {
                id
                advertiserName
            }
        }
    }
`;

const PacingPage = () => {
    const { loading, data } = useQuery(GET_MY_PACING_DATA, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const placements: PacingData[] = compact(data?.getMyPacingData || []);

    if (loading) return <LoadingBlock message='Gathering Pacing Information. Hang Tight!' />;
    return <PacingView data={placements} />;
};

export default PacingPage;
