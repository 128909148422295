import React, { useMemo } from 'react';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Area } from 'recharts';
import dayjs from 'dayjs';
import { formatToUSCurrency } from '../../helpers/formatters';
import { chartToolTipStyle } from '../../style/chartTooltip';

const DEFAULT_STROKE_WIDTH = 2;
const X_AXIS_TIC_INTERVAL = 1;
const CURVE_TYPE = 'linear';

type PacingChartProps = {
    pacingHistory: PacingHistory[];
    startDate: string;
    endDate: string;
};

interface AnnotatedPacingHistory extends PacingHistory {
    plannedAmountPerDay: number;
    failedAmount: number | null;
    cumulativeAmount: number;
    cumulativePlannedAmount: number;
    cumulativeFailedAmount: number | null;
    cumulativePlannedAmountHighThresholdActual: number;
    cumulativePlannedAmountLowThresholdActual: number;
    cumulativePlannedAmountHighThresholdGraph: number;
    cumulativePlannedAmountLowThresholdGraph: number;
}
interface PacingHistoryAccumulator extends AnnotatedPacingHistory {
    sortedData: AnnotatedPacingHistory[];
    daysPassed: number;
    daysFailed: number;
    daysTotal: number;
}

type CustomTooltipPayload = {
    payload: PacingHistoryAccumulator;
    checkDate: string;
};

type CustomTooltipProps = {
    active?: boolean;
    payload?: CustomTooltipPayload[];
    label?: string;
};

const prepareData = (startDate: string, endDate: string, pacingHistory: PacingHistory[]) => {
    const totalDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
    const sortedData = pacingHistory
        .sort((a, b) => dayjs(a.checkDate).diff(dayjs(b.checkDate), 'day'))
        .reduce<PacingHistoryAccumulator>(
            (acc, cur) => {
                const plannedAmountPerDay = Math.floor((cur.plannedAmount / totalDays) * 100) / 100;
                acc.daysTotal += 1;
                acc.daysFailed += cur.status === 'FAILURE' ? 1 : 0;
                acc.daysPassed += cur.status === 'SUCCESS' ? 1 : 0;
                acc.cumulativeAmount += cur.latestDateDeliveredAmount;
                acc.cumulativePlannedAmount += plannedAmountPerDay;
                acc.cumulativePlannedAmountLowThresholdActual = acc.cumulativePlannedAmount * 0.84;
                acc.cumulativePlannedAmountLowThresholdGraph = acc.cumulativePlannedAmountLowThresholdActual;
                acc.cumulativePlannedAmountHighThresholdActual = acc.cumulativePlannedAmount * 1.2;
                acc.cumulativePlannedAmountHighThresholdGraph =
                    acc.cumulativePlannedAmountHighThresholdActual - acc.cumulativePlannedAmountLowThresholdGraph;

                acc.sortedData.push({
                    ...cur,
                    plannedAmountPerDay,
                    failedAmount: cur.status === 'FAILURE' ? cur.latestDateDeliveredAmount : null,
                    cumulativeFailedAmount: cur.status === 'FAILURE' ? acc.cumulativeAmount : null,
                    cumulativeAmount: acc.cumulativeAmount,
                    cumulativePlannedAmount: acc.cumulativePlannedAmount,
                    cumulativePlannedAmountHighThresholdGraph: acc.cumulativePlannedAmountHighThresholdGraph,
                    cumulativePlannedAmountLowThresholdGraph: acc.cumulativePlannedAmountLowThresholdGraph,
                    cumulativePlannedAmountHighThresholdActual: acc.cumulativePlannedAmountHighThresholdActual,
                    cumulativePlannedAmountLowThresholdActual: acc.cumulativePlannedAmountLowThresholdActual,
                });
                return acc;
            },
            {
                sortedData: [],
                daysPassed: 0,
                daysFailed: 0,
                daysTotal: 0,
                cumulativeAmount: 0,
                cumulativePlannedAmount: 0,
                cumulativePlannedAmountHighThresholdActual: 0,
                cumulativePlannedAmountLowThresholdActual: 0,
                cumulativePlannedAmountHighThresholdGraph: 0,
                cumulativePlannedAmountLowThresholdGraph: 0,
                plannedAmountPerDay: 0,
                failedAmount: 0,
                cumulativeFailedAmount: 0,
                ...pacingHistory[0],
            } as PacingHistoryAccumulator
        );

    return sortedData;
};

const CustomTooltip = (props: CustomTooltipProps) => {
    // Follows example found here: https://recharts.org/en-US/examples/CustomContentOfTooltip
    const { active, payload } = props;
    if (active && payload && payload.length) {
        const {
            cumulativeAmount,
            checkDate,
            cumulativePlannedAmountHighThresholdActual,
            cumulativePlannedAmountLowThresholdActual,
        } = payload[0].payload;
        return (
            <div style={chartToolTipStyle}>
                <div style={{ fontWeight: 'bold' }}>{`${dayjs(checkDate).format('dddd, MMMM D, YYYY')}`}</div>
                {cumulativeAmount != null && <div>Cumulative Spend: {formatToUSCurrency(cumulativeAmount)}</div>}
                {cumulativeAmount != null && (
                    <div>
                        Safe Range: {formatToUSCurrency(cumulativePlannedAmountLowThresholdActual)} to{' '}
                        {formatToUSCurrency(cumulativePlannedAmountHighThresholdActual)}{' '}
                    </div>
                )}
            </div>
        );
    }

    return <></>;
};

const PacingChartCumulative: React.FC<PacingChartProps> = ({ startDate, endDate, pacingHistory }) => {
    const pacingHistoryObj = useMemo(
        () => prepareData(startDate, endDate, pacingHistory),
        [pacingHistory, startDate, endDate]
    );
    const { sortedData, daysPassed, daysFailed } = pacingHistoryObj;
    return (
        <div style={{ width: '100%', height: '30em' }}>
            <h3 style={{ textAlign: 'center' }}>Cumulative Pacing</h3>
            <ResponsiveContainer width='100%' height='100%'>
                <ComposedChart
                    width={800}
                    height={500}
                    data={sortedData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 30,
                        bottom: 20,
                    }}
                    style={{ fontFamily: 'roboto' }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                        dataKey='checkDate'
                        angle={-45}
                        textAnchor='end'
                        minTickGap={100}
                        interval={X_AXIS_TIC_INTERVAL}
                        height={50}
                    />
                    <YAxis tickFormatter={formatToUSCurrency} />
                    <Legend verticalAlign='top' align='right' />
                    <Area
                        type={CURVE_TYPE}
                        dataKey={'cumulativePlannedAmountLowThresholdGraph'}
                        stackId={1}
                        name='low bottom'
                        isAnimationActive={false}
                        fill='transparent'
                        stroke='transparent'
                    />
                    <Area
                        type={CURVE_TYPE}
                        dataKey={'cumulativePlannedAmountHighThresholdGraph'}
                        stackId={1}
                        isAnimationActive={false}
                        fill='#bbb9b9'
                        stroke='transparent'
                        name='Even Spend'
                    />
                    <Line
                        type={CURVE_TYPE}
                        dataKey='cumulativePlannedAmount'
                        name='Even Spend'
                        stroke='#1B1D31'
                        strokeWidth={DEFAULT_STROKE_WIDTH - 1}
                        activeDot={{ r: 6 }}
                        isAnimationActive={false}
                        strokeDasharray='3 3'
                        dot={false}
                    />
                    {daysPassed > 0 && (
                        <Line
                            type={CURVE_TYPE}
                            dataKey='cumulativeAmount'
                            name='Actual Spend'
                            stroke='#007DC6'
                            strokeWidth={DEFAULT_STROKE_WIDTH}
                            activeDot={{ r: 6 }}
                            isAnimationActive={false}
                            connectNulls={false}
                        />
                    )}
                    {daysFailed > 0 && (
                        <Line
                            type={CURVE_TYPE}
                            dataKey='cumulativeFailedAmount'
                            name='Actual Spend (Outside Threshold)'
                            stroke='#c60000'
                            strokeWidth={DEFAULT_STROKE_WIDTH}
                            activeDot={{ r: 6 }}
                            isAnimationActive={false}
                            connectNulls={false}
                        />
                    )}
                    <Tooltip content={<CustomTooltip />} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PacingChartCumulative;
