import React from 'react';
import { useQuery, gql } from '@apollo/client';
import compact from 'lodash/compact';
import { Grid, Divider } from '@mui/material';
import LoadingBlock from '../atoms/LoadingBlock';
import PacingChart from './PacingChart';
import PacingChartCumulative from './PacingChartCumulative';

const GET_HISTORICAL_PACING_DATA = gql`
    query getHistoricalPacingData($placementId: ID!) {
        getHistoricalPacingData(placementId: $placementId) {
            id
            executionDate
            checkDate
            pacingType
            plannedAmount
            deliveredAmount
            deliveredImpressions
            remainingDailyAmount
            remainingDailyImpressions
            latestDateDeliveredAmount
            flightPercentage
            pacingPercentage
            status
            message
        }
    }
`;

type PacingHistoryTabProps = {
    advertiserName?: string;
    buyType?: string;
    checkDate?: string;
    executionDate?: string;
    message?: string;
    meta?: string;
    monitorDisplayName?: string;
    placementId: string;
    placementName?: string;
    platform?: Platform;
    status?: string;
    startDate: string;
    endDate: string;
};

const PacingHistoryTab = ({ placementId, startDate, endDate }: PacingHistoryTabProps) => {
    const { loading, data } = useQuery(GET_HISTORICAL_PACING_DATA, {
        variables: {
            placementId,
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    if (loading) return <LoadingBlock message='Organizing chronicles of the past. Please endure.' />;

    const pacingHistory: PacingHistory[] = compact(data?.getHistoricalPacingData || []);

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <PacingChart startDate={startDate} endDate={endDate} pacingHistory={pacingHistory} />
            </Grid>
            <Divider sx={{ marginBottom: '10px', marginTop: '20px', paddingTop: '2em' }} />
            <Grid item>
                <PacingChartCumulative startDate={startDate} endDate={endDate} pacingHistory={pacingHistory} />
            </Grid>
        </Grid>
    );
};

export default PacingHistoryTab;
