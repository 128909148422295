import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const CloseButtonX = ({ closeSnackbar, snackbarId }) => (
    <IconButton
        sx={{ color: 'currentColor' }}
        aria-label='close'
        onClick={() => {
            closeSnackbar(snackbarId);
        }}
    >
        <CloseIcon fontSize='small' />
    </IconButton>
);

CloseButtonX.propTypes = {
    closeSnackbar: PropTypes.func.isRequired,
    snackbarId: PropTypes.number.isRequired,
};

export const createCloseButtonX = (closeSnackbar) =>
    function closeToast(snackbarId) {
        return <CloseButtonX closeSnackbar={closeSnackbar} snackbarId={snackbarId} />;
    };
