import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
import LoadingBlock from '../atoms/LoadingBlock';
import MyPlacementsViewV2 from '../molecules/MyPlacementsViewV2';

const GET_MY_MEDIAOCEAN_PLACEMENTS = gql`
    query getMyMediaoceanPlacements {
        getMyMediaoceanPlacements {
            id
            placementId
            placementName
            advertiserName
            campaignName
            platform
            buyType
            startDate
            endDate
            grossBillable
            currentPhase
            smartguardMonitors {
                id
                executionDate
                checkDate
                name
                status
                meta
                message
            }
        }
    }
`;

const GET_FOLLOWED_ADVERTISERS = gql`
    query getFollowedAdvertisers {
        getFollowedAdvertisers {
            id
            advertiser {
                id
                advertiserName
            }
        }
    }
`;

const MyPlacementsPage = () => {
    const { loading: placementsLoading, data: placementsData } = useQuery(GET_MY_MEDIAOCEAN_PLACEMENTS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });
    const { loading: advertisersLoading, data: advertisersData } = useQuery(GET_FOLLOWED_ADVERTISERS, {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const placements: UnformattedPlacement[] = compact(placementsData?.getMyMediaoceanPlacements || []);
    const advertisers: FollowedAdvertiser[] = compact(advertisersData?.getFollowedAdvertisers || []);

    const rows = useMemo<FormattedPlacement[]>(
        () =>
            placements.map(
                ({
                    id,
                    placementId,
                    placementName,
                    advertiserName,
                    campaignName,
                    platform,
                    buyType,
                    startDate,
                    endDate,
                    grossBillable,
                    currentPhase,
                    smartguardMonitors,
                }) => ({
                    id,
                    placementId,
                    placementName,
                    advertiserName,
                    campaignName,
                    platform,
                    buyType,
                    startDate,
                    endDate,
                    grossBillable,
                    currentPhase,
                    ...keyBy(smartguardMonitors, (monitor) => {
                        const [firstWord, ...rest] = monitor?.name?.split(' ');
                        return [firstWord.toLowerCase(), ...rest].join('');
                    }),
                })
            ),
        [placements]
    );

    if (placementsLoading || advertisersLoading)
        return <LoadingBlock message='Gathering your Campaigns. Be right back!' />;
    return <MyPlacementsViewV2 data={rows} advertisers={advertisers} />;
};

export default MyPlacementsPage;
