import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import { useQuery, gql } from '@apollo/client';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import AdvertiserPlacementsView from '../molecules/AdvertiserPlacementsView';
import PlatformIcon from '../atoms/PlatformIcon';
import LoadingBlock from '../atoms/LoadingBlock';
import MonitorPopover from '../molecules/MonitorPopover';

const GET_MEDIAOCEAN_ADVERTISER_BY_CODE = gql`
    query getMediaoceanAdvertiserByCode($advertiserCode: ID!) {
        getMediaoceanAdvertiserByCode(advertiserCode: $advertiserCode) {
            id
            advertiserCode
            advertiserName
            isFollowed
            placements {
                id
                placementId
                placementName
                campaignName
                platform
                buyType
                startDate
                endDate
                grossBillable
                smartguardMonitors {
                    id
                    lastExecutionDate
                    ruleName
                    status
                }
            }
        }
    }
`;

const columns: GridColDef[] = [
    {
        headerName: 'Placement ID',
        field: 'placementId',
        flex: 1,
    },
    {
        headerName: 'Placement Name',
        field: 'placementName',
        flex: 1,
    },
    {
        headerName: 'Campaign Name',
        field: 'campaignName',
        flex: 1,
    },
    {
        headerName: 'Platform',
        headerAlign: 'center',
        field: 'platform',
        flex: 1,
        renderCell: (props: GridRenderCellParams) => (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <PlatformIcon platform={props?.value} size={30} />
            </Box>
        ),
    },
    {
        headerName: 'Buy Type',
        field: 'buyType',
        flex: 1,
    },
    {
        headerName: 'Start Date',
        field: 'startDate',
        flex: 1,
    },
    {
        headerName: 'End Date',
        field: 'endDate',
        flex: 1,
    },
    {
        headerName: 'Gross Billable',
        field: 'grossBillable',
        flex: 1,
        renderCell: (props: GridRenderCellParams) => {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            const formattedValue = formatter.format(props?.value);
            return formattedValue;
        },
    },
    {
        headerName: 'Launch Delivery',
        headerAlign: 'center',
        field: 'launchDelivery',
        flex: 1,
        pinnable: false,
        renderCell: (props: GridRenderCellParams) => (
            <MonitorPopover
                status={props?.value?.status}
                name={props?.value?.ruleName}
                lastRunDate={props?.value?.lastExecutionDate}
            />
        ),
    },
    {
        headerName: 'Delivery Threshold',
        headerAlign: 'center',
        field: 'deliveryThreshold',
        flex: 1,
        pinnable: false,
        renderCell: (props: GridRenderCellParams) => (
            <MonitorPopover
                status={props?.value?.status}
                name={props?.value?.ruleName}
                lastRunDate={props?.value?.lastExecutionDate}
            />
        ),
    },
    {
        headerName: 'Activity Trend',
        headerAlign: 'center',
        field: 'activityTrend',
        flex: 1,
        pinnable: false,
        renderCell: (props: GridRenderCellParams) => (
            <MonitorPopover
                status={props?.value?.status}
                name={props?.value?.ruleName}
                lastRunDate={props?.value?.lastExecutionDate}
            />
        ),
    },
];

const AdvertiserPlacementsPage = () => {
    const { advertiserCode } = useParams();

    const { loading, data } = useQuery(GET_MEDIAOCEAN_ADVERTISER_BY_CODE, {
        variables: {
            advertiserCode,
        },
        errorPolicy: 'all',
    });

    const advertiserName: string = data?.getMediaoceanAdvertiserByCode?.advertiserName;
    const isFollowed: boolean = data?.getMediaoceanAdvertiserByCode?.isFollowed;
    const placements: UnformattedPlacement[] = compact(data?.getMediaoceanAdvertiserByCode?.placements || []);

    const rows = useMemo<FormattedPlacement[]>(
        () =>
            placements.map(
                ({
                    id,
                    placementId,
                    placementName,
                    campaignName,
                    platform,
                    buyType,
                    startDate,
                    endDate,
                    grossBillable,
                    currentPhase,
                    smartguardMonitors,
                }) => ({
                    id,
                    placementId,
                    placementName,
                    campaignName,
                    platform,
                    buyType,
                    startDate,
                    endDate,
                    grossBillable,
                    currentPhase,
                    ...keyBy(smartguardMonitors, (monitor) => {
                        const [firstWord, ...rest] = monitor?.name?.split(' ');
                        return [firstWord.toLowerCase(), ...rest].join('');
                    }),
                })
            ),
        [placements]
    );

    if (loading) return <LoadingBlock />;
    return (
        <AdvertiserPlacementsView
            rows={rows}
            columns={columns}
            advertiserCode={advertiserCode}
            advertiserName={advertiserName}
            isFollowed={isFollowed}
        />
    );
};

export default AdvertiserPlacementsPage;
