import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

type LoadingBlockProps = {
    message?: string;
};

const LoadingBlock: React.FC<LoadingBlockProps> = ({ message = null }) => (
    <Box
        sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1 1 auto',
            flexDirection: 'column',
        }}
    >
        <CircularProgress size={100} />
        <br />
        {message && <Typography>{message}</Typography>}
    </Box>
);

export default LoadingBlock;
