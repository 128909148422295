import { GoodwayTheme } from '@goodwaygroup/ui-ux';
import { pascalCaseWord } from '../helpers/formatters';

export const MAX_TOAST_MESSAGES = 5;

export const DEFAULT_TOAST_DURATION = 5000; // 5 seconds

export const SEVERITY = {
    DEFAULT: 'default',
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARN: 'warning',
};

export const TOAST_STYLE_OVERRIDES = {
    '& .SnackbarItem-contentRoot': {
        color: `rgb(0, 0, 0, .8) !important`, // light text color (aka black)
        backgroundColor: `${GoodwayTheme.light.palette.alertColors.default.backgroundColor} !important`,
        '.SnackbarItem-message .MuiSvgIcon-root': {
            color: GoodwayTheme.light.palette.alertColors.default.iconColor,
        },
    },
    [`& .SnackbarItem-variant${pascalCaseWord(SEVERITY.ERROR)}`]: {
        color: `rgb(0, 0, 0, .8) !important`,
        backgroundColor: `${GoodwayTheme.light.palette.alertColors.error.backgroundColor} !important`,
        '.SnackbarItem-message .MuiSvgIcon-root': {
            color: GoodwayTheme.light.palette.alertColors.error.iconColor,
        },
    },
    [`& .SnackbarItem-variant${pascalCaseWord(SEVERITY.INFO)}`]: {
        color: `rgb(0, 0, 0, .8) !important`,
        backgroundColor: `${GoodwayTheme.light.palette.alertColors.info.backgroundColor} !important`,
        '.SnackbarItem-message .MuiSvgIcon-root': {
            color: GoodwayTheme.light.palette.alertColors.info.iconColor,
        },
    },
    [`& .SnackbarItem-variant${pascalCaseWord(SEVERITY.SUCCESS)}`]: {
        color: `rgb(0, 0, 0, .8) !important`,
        backgroundColor: `${GoodwayTheme.light.palette.alertColors.success.backgroundColor} !important`,
        '.SnackbarItem-message .MuiSvgIcon-root': {
            color: GoodwayTheme.light.palette.alertColors.success.iconColor,
        },
    },
    [`& .SnackbarItem-variant${pascalCaseWord(SEVERITY.WARN)}`]: {
        color: `rgb(0, 0, 0, .8) !important`,
        backgroundColor: `${GoodwayTheme.light.palette.alertColors.warning.backgroundColor} !important`,
        '.SnackbarItem-message .MuiSvgIcon-root': {
            color: GoodwayTheme.light.palette.alertColors.warning.iconColor,
        },
    },
};

export const DEFAULT_SEVERITY = SEVERITY.DEFAULT;
