import { Tabs, Tab } from '@mui/material';
import monitorTabConfig from '../../helpers/monitorTabConfig';

interface DynamicTabsProps {
    monitorName: MonitorRule;
    currentTabIndex: number;
    onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const DynamicTabs = ({ monitorName, currentTabIndex, onTabChange }: DynamicTabsProps) => {
    const tabsToRender = monitorTabConfig[monitorName];

    return (
        <Tabs value={currentTabIndex} onChange={onTabChange} sx={{ marginBottom: 2 }}>
            {tabsToRender.map(({ label, icon }, index) => (
                <Tab key={index} label={label} icon={icon} />
            ))}
        </Tabs>
    );
};

export default DynamicTabs;
