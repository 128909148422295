import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InfoIcon from '@mui/icons-material/Info';

const monitorTabConfig: Record<string, { label: string; icon: JSX.Element }[]> = {
    launchVerification: [
        { label: 'Check Details', icon: <ChecklistRtlIcon /> },
        { label: 'How it Works', icon: <InfoIcon /> },
    ],
    deliveryCheck: [
        { label: 'Check Details', icon: <ChecklistRtlIcon /> },
        { label: 'How it Works', icon: <InfoIcon /> },
    ],
    marginThreshold: [
        { label: 'Check Details', icon: <ChecklistRtlIcon /> },
        { label: 'How it Works', icon: <InfoIcon /> },
    ],
    activityTrend: [
        { label: 'Check Details', icon: <ChecklistRtlIcon /> },
        { label: 'Placement History', icon: <QueryStatsIcon /> },
        { label: 'How it Works', icon: <InfoIcon /> },
    ],
    flightPacing: [
        { label: 'Check Details', icon: <ChecklistRtlIcon /> },
        { label: 'Placement History', icon: <QueryStatsIcon /> },
        { label: 'How it Works', icon: <InfoIcon /> },
    ],
};

export default monitorTabConfig;
