import { GridFilterModel, GridLinkOperator, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Beenhere, Circle, WarningAmber } from '@mui/icons-material';
import { LicenseInfo } from '@mui/x-license-pro';
import { useEffect, useState } from 'react';
import MyPlacementsDataGrid from './MyPlacementsDataGrid';
import Env from '../../helpers/Env';
import { generateConfigFilterInputs } from '../../helpers/generateConfigFilterInputs';
import { platformLabels } from '../../constants/platformLabels';
import { buyTypeLabels } from '../../constants/buyTypeLabels';
import { phaseLabels } from '../../constants/phaseLabels';
import { checkAdvertiserNoPlacementData } from '../../helpers/checkAdvertiserNoPlacementData';
import ChipPopover from './ChipPopover';
import ViewHeader from '../atoms/ViewHeader';
import TableWithFiltersLayout from '../templates/TableWithFiltersLayout';
import TableFilter from './TableFilter';

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

type MyPlacementsViewProps = {
    data: FormattedPlacement[];
    advertisers: FollowedAdvertiser[];
};

const MyPlacementsViewV2 = ({ data, advertisers }: MyPlacementsViewProps) => {
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.And,
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        all: true,
        // currentPhase: false,
        buyType: false,
        advertiserName: false,
    });

    // Popover state and anchor
    const [advertiserNoPlacementData, setAdvertiserNoPlacementData] = useState<FollowedAdvertiser[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLeave = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'chip-popover-content' : undefined;

    useEffect(() => {
        const advertisersWithNoActivePlacements = checkAdvertiserNoPlacementData(data, advertisers);
        setAdvertiserNoPlacementData(advertisersWithNoActivePlacements);
    }, [data, advertisers]);

    const filterConfigs: TableFilterConfig[] = [
        {
            title: 'Advertisers',
            filterType: 'checkbox',
            filterColumn: 'advertiserName',
            filterInputs: generateConfigFilterInputs(data, (item) => item.advertiserName || '', 'equals'), // passing empty string to satisfy generateConfigFilterInputs
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
            defaultExpanded: true,
        },
        {
            title: 'Platform',
            filterType: 'checkbox',
            filterColumn: 'platform',
            filterInputs: generateConfigFilterInputs(data, (item) => item.platform, 'equals', platformLabels),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
        {
            title: 'Buy Type',
            filterType: 'checkbox',
            filterColumn: 'buyType',
            filterInputs: generateConfigFilterInputs(data, (item) => item.buyType, 'equals', buyTypeLabels),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
        {
            title: 'Phase',
            filterType: 'checkbox',
            filterColumn: 'currentPhase',
            filterInputs: generateConfigFilterInputs(data, (item) => item.currentPhase, 'equals', phaseLabels),
            filterModel: {
                model: filterModel,
                setModel: setFilterModel,
            },
        },
    ];

    const filters = [...filterConfigs.map((config, index) => <TableFilter key={index} config={config} />)];

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    maxHeight: '100%',
                    justifyContent: 'flex-start',
                }}
            >
                <ViewHeader title='Campaign Status' icon={<Beenhere />} />
                {advertiserNoPlacementData.length !== advertisers.length && advertisers.length > 0 && (
                    <ChipPopover
                        chipConfig={{
                            icon: <WarningAmber />,
                            handleEnter,
                            handleLeave,
                            label: `No Active Placements`,
                            size: 'small',
                            color: 'warning',
                            variant: 'outlined',
                        }}
                        popoverConfig={{
                            popoverId: id,
                            popoverOpen: open,
                            popoverAnchor: anchorEl,
                        }}
                    >
                        <Box sx={{ padding: '10px' }}>
                            <Typography
                                sx={{
                                    padding: '20px 20px 10px',
                                }}
                                variant='subtitle2'
                            >
                                The following advertisers have no active placements
                            </Typography>
                            <List>
                                {advertiserNoPlacementData.map((advertiser) => (
                                    <ListItem key={advertiser.id} dense sx={{ justifyContent: 'center' }}>
                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                            <Circle sx={{ fontSize: '6px', color: 'black' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={advertiser.advertiser.advertiserName} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </ChipPopover>
                )}
            </Box>
            <TableWithFiltersLayout filters={filters}>
                <MyPlacementsDataGrid
                    setFilterModel={setFilterModel}
                    filterModel={filterModel}
                    columnVisibilityModel={columnVisibilityModel}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    data={data}
                    advertisers={advertisers}
                />
            </TableWithFiltersLayout>
        </>
    );
};
export default MyPlacementsViewV2;
