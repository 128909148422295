import { ErrorBoundary, Provider } from '@rollbar/react';
import PropTypes from 'prop-types';
import { version } from '../../../package.json';
import Env from '../../helpers/Env';
import ErrorMessage from '../molecules/ErrorMessage';

/**
 * Notes:
 * To manually log errors in Rollbar use the useRollbar hook and then call the
 * error method. The signature of the funciton is:
 * error("error_description", error_object, optional_params_object_for_context).
 */

/**
 * Rollbar configuration options
 * @ref: https://docs.rollbar.com/docs/rollbarjs-configuration-reference
 */
const rollbarConfig = {
    enabled: !!Env('VITE_APP_ROLLBAR_ACCESS_TOKEN'), // disable rollbar if we dont have an access token
    accessToken: Env('VITE_APP_ROLLBAR_ACCESS_TOKEN') || '',
    captureUncaught: true, // report all uncaught exceptions
    captureUnhandledRejections: true, // report all uncaught rejections
    payload: {
        environment: Env('VITE_NODE_ENV'),
        client: {
            javascript: {
                code_version: `v${version}`,
                source_map_enabled: true, // helps determine error lines

                // Optionally have Rollbar guess which frames the error was
                // thrown from when the browser does not provide line
                // and column numbers.
                guess_uncaught_frames: true,
            },
        },
        server: {
            root: window.location.origin, // base for context in Rollbar
        },
        sendConfig: false,
    },
};

// Error fallback ui
const ErrorDisplay = (error) => {
    // eslint-disable-next-line no-console
    console.log(error.message);
    return <ErrorMessage>{error.error.message}</ErrorMessage>;
};

// Provider to wrap around app and top level error boundary
const RollbarProvider = ({ children }) => (
    <Provider config={rollbarConfig}>
        <ErrorBoundary fallbackUI={ErrorDisplay}>{children}</ErrorBoundary>
    </Provider>
);

RollbarProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export default RollbarProvider;
