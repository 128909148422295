import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { phaseGroupings } from '../constants/phaseGroupings';

export const checkMonitorInCurrentPhase = (params: GridRenderCellParams) => {
    const monitorCheck = params.field as MonitorRule;
    const monitorPhase = phaseGroupings.find((phase) => phase.monitors.includes(monitorCheck));

    if (!monitorPhase) {
        return false;
    }

    const { currentPhase } = params.row;

    if (monitorPhase.phase !== currentPhase) {
        const monitorIndex = phaseGroupings.findIndex((phase) => phase.phase === monitorPhase.phase);
        const currentMonitorIndex = phaseGroupings.findIndex((phase) => phase.phase === currentPhase);

        return currentMonitorIndex >= monitorIndex;
    }

    return true;
};
