import React, { useState } from 'react';
import {
    DataGridPro,
    GridColDef,
    GridToolbar,
    GridRenderCellParams,
    GridFilterModel,
    GridRowParams,
    // getGridDateOperators,
    GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import dayjs from 'dayjs';
import PlatformIcon from '../atoms/PlatformIcon';
import HeaderCell from '../atoms/HeaderCell';
import Env from '../../helpers/Env';
import PacingDrawer from './PacingDrawer';
import PacingPercentage from '../atoms/PacingPercentage';
import { formatToUSCurrency } from '../../helpers/formatters';

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

type PacingDataGridProps = {
    data: PacingData[];
    filterModel: GridFilterModel;
    setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
    columnVisibilityModel: GridColumnVisibilityModel;
    setColumnVisibilityModel: React.Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
};

const PacingDataGrid = ({
    data,
    filterModel,
    setFilterModel,
    columnVisibilityModel,
    setColumnVisibilityModel,
}: PacingDataGridProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [rowParams, setRowParams] = useState<GridRowParams | null>(null);
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            headerName: 'Date',
            headerAlign: 'center',
            field: 'executionDate',
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        minWidth: 100,
                        height: 'auto',
                    }}
                >
                    {dayjs(params.value).format('MM/DD/YYYY')}
                </div>
            ),
        },
        {
            headerName: 'Platform',
            headerAlign: 'center',
            field: 'platform',
            valueGetter: (params) => params.row.placement.platform,
            renderCell: (props: GridRenderCellParams) => (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <PlatformIcon platform={props?.value} size={30} />
                </Box>
            ),
        },
        {
            headerName: 'Campaign Name',
            field: 'campaignName',
            minWidth: 300,
            valueGetter: (params) => params.row.placement.campaignName,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Placement Name',
            field: 'placementName',
            valueGetter: (params) => params.row.placement.placementName,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Advertiser',
            field: 'advertiserName',
            valueGetter: (params) => params.row.advertiser.advertiserName,
            minWidth: 200,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Pacing Type',
            field: 'pacingType',
            maxWidth: 100,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        minWidth: 100,
                        height: 'auto',
                    }}
                >
                    {params.value}
                </div>
            ),
            renderHeader: () => <HeaderCell headerName='Pacing Type' />,
        },
        {
            headerName: 'Pacing',
            field: 'pacingPercentage',
            maxWidth: 200,
            minWidth: 150,
            renderCell: (props: GridRenderCellParams) => (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <PacingPercentage percentage={props?.value} status={props?.row.status} />
                </Box>
            ),
            renderHeader: () => <HeaderCell headerName='Pacing' />,
        },
        {
            headerName: 'Status',
            field: 'status',
            valueGetter: (params) => params.row.status,
            minWidth: 150,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: 200, height: 'auto' }}>
                    {params.value}
                </div>
            ),
        },
        {
            headerName: 'Planned Amount',
            field: 'plannedAmount',
            minWidth: 150,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        minWidth: 150,
                        height: 'auto',
                    }}
                >
                    {formatToUSCurrency(params.value)}
                </div>
            ),
            renderHeader: () => <HeaderCell headerName='Planned Amount' width='150px' />,
        },
        {
            headerName: 'Delivered Amount',
            field: 'deliveredAmount',
            minWidth: 150,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        minWidth: 150,
                        height: 'auto',
                    }}
                >
                    {formatToUSCurrency(params.value)}
                </div>
            ),
            renderHeader: () => <HeaderCell headerName='Delivered Amount' width='150px' />,
        },
        {
            headerName: 'Remaining Amount',
            field: 'remainingDailyAmount',
            minWidth: 150,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        minWidth: 150,
                        height: 'auto',
                    }}
                >
                    {formatToUSCurrency(params.value)}
                </div>
            ),
            renderHeader: () => <HeaderCell headerName='Remaining Amount' width='150px' />,
        },
        {
            headerName: 'Latest Date Delivered Amount',
            field: 'latestDateDeliveredAmount',
            minWidth: 150,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        minWidth: 150,
                        height: 'auto',
                    }}
                >
                    {formatToUSCurrency(params.value)}
                </div>
            ),
            renderHeader: () => <HeaderCell headerName='Latest Date Delivered Amount' width='150px' />,
        },
    ];

    const handleRowClick = (params: GridRowParams) => {
        setRowParams(params);
        setIsOpen(true);
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: 'calc(100% + 40px)',
                maxHeight: '100%',
                justifyContent: 'flex-start',
                '& .qa-datagrid-monitor--cell': {
                    padding: ' 0 !important',
                    margin: 0,
                },
                width: '100%',
            }}
        >
            <DataGridPro
                sx={{
                    '& .MuiDataGrid-virtualScroller': {
                        pointerEvents: !data.length ? 'none' : 'all',
                    },
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                    },
                }}
                density='standard'
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'campaignName', sort: 'desc' }],
                    },
                }}
                rows={data}
                columns={columns}
                getRowId={(row) => row.id}
                rowHeight={90}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        printOptions: { disableToolbarButton: true },
                    },
                }}
                filterModel={filterModel}
                onFilterModelChange={(prev) => setFilterModel(prev)}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                components={{
                    Toolbar: GridToolbar,
                    NoRowsOverlay: () => (
                        <Stack height='100%' alignItems='center' justifyContent='center'>
                            <Typography sx={{ marginBottom: '10px' }} variant='h5'>
                                Follow Advertisers To View Pacing Information
                            </Typography>
                            <Typography variant='body2' sx={{ marginBottom: '20px' }}>
                                Placements from advertisers you follow will show here
                            </Typography>
                            <Button
                                variant='contained'
                                sx={{ minWidth: '100px' }}
                                onClick={() => navigate('/advertisers')}
                            >
                                Go To Advertisers
                            </Button>
                        </Stack>
                    ),
                }}
                onRowClick={(params) => {
                    handleRowClick(params);
                }}
            />
            {rowParams && <PacingDrawer open={isOpen} setOpen={setIsOpen} rowParams={rowParams} />}
        </Box>
    );
};

export default PacingDataGrid;
