/* global pendo */

import React from 'react';
import PropTypes from 'prop-types';
import env from '../../helpers/Env';

const shouldInitialize = env('NODE_ENV') === 'production' || env('NODE_ENV') === 'staging';

export default function LoadPendo({ user }) {
    const { id, fullName: name, email } = user;

    return (
        <>
            {shouldInitialize &&
                pendo.initialize({
                    visitor: {
                        id,
                        email,
                        full_name: name,
                    },

                    account: {
                        id: 'GW0001',
                        name: 'Goodway Group',
                        is_paying: false,
                        monthly_value: 0,
                    },
                })}
        </>
    );
}

LoadPendo.propTypes = {
    user: PropTypes.object.isRequired,
};
