import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box, Typography, Breadcrumbs, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import sgLogo from '../../assets/smartguard-logo.png';
import Env from '../../helpers/Env';
import FollowAdvertiserButton from '../atoms/FollowAdvertiserButton';

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

type AdvertiserPlacementsViewProps = {
    rows: FormattedPlacement[];
    columns: GridColDef[];
    advertiserCode: string | undefined;
    advertiserName: string | undefined;
    isFollowed: boolean;
};

const AdvertiserPlacementsView = ({
    columns = [],
    rows = [],
    advertiserCode,
    advertiserName,
    isFollowed,
}: AdvertiserPlacementsViewProps) => (
    <>
        <Breadcrumbs sx={{ marginTop: '20px' }} aria-label='breadcrumb'>
            <Link component={RouterLink} underline='hover' color='inherit' to='/advertisers'>
                Advertisers
            </Link>
            <Typography color='text.primary'>{advertiserName}</Typography>
        </Breadcrumbs>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Typography sx={{ margin: '10px 20px 0 0' }} variant='h4'>
                {advertiserName}
            </Typography>
            <FollowAdvertiserButton advertiserCode={advertiserCode} isFollowed={isFollowed} />
        </Box>
        <Typography sx={{ margin: '10px 0' }} variant='subtitle1'>
            {rows.length} Placements
        </Typography>
        <DataGridPro
            initialState={{ pinnedColumns: { right: ['launchDelivery', 'deliveryThreshold', 'activityTrend'] } }}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={[
                {
                    groupId: 'Smartguard',
                    headerAlign: 'center',
                    description: 'SmartGuard Monitors',
                    children: [{ field: 'launchDelivery' }, { field: 'deliveryThreshold' }, { field: 'activityTrend' }],
                    renderHeaderGroup: () => (
                        <Box
                            sx={{
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                width: '129px',
                                height: '22px',
                                backgroundImage: `url(${sgLogo})`,
                            }}
                        />
                    ),
                },
            ]}
        />
    </>
);
export default AdvertiserPlacementsView;
